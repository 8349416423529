import React from 'react'
import {NominationsTableCalendar} from './NominationsTableCalendar'
import {NominationsTableDetail} from './NominationsTableDetail'
import {useBucketStore} from '../../utils/BucketStoreContext'

export const NominationsTable: React.FC<{baseDate: string}> = () => {
  const {data: bpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')

  return (
    <>
      {!bpsNominationTableOpen && <NominationsTableCalendar />}
      {bpsNominationTableOpen && <NominationsTableDetail />}
    </>
  )
}
