import {styled, Tooltip} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {getBpsNominationsMinMax} from '../../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {BpsCalculatedNominationValueColorEnum, BpsNominationsMinimumAndMaximum} from '../../api/generated'
import {CalendarPicker, PickersDay} from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import {transformToKwWithEndKw} from '../../utils/common'

const CustomCalendarPicker = styled(CalendarPicker)(() => ({
  width: '100%',
  margin: 0,

  '.MuiDayPicker-header': {
    justifyContent: 'space-around',
  },

  '.MuiDayPicker-weekContainer': {
    justifyContent: 'space-around',
  },
}))

const StyledPickersDay = styled(PickersDay)(({color}) => {
  let bgColor = '#fff'
  if (color == BpsCalculatedNominationValueColorEnum.Orange) {
    bgColor = '#ff8c5499'
  } else if (color == BpsCalculatedNominationValueColorEnum.Red) {
    bgColor = '#ff41418a'
  } else if (color == BpsCalculatedNominationValueColorEnum.Green) {
    bgColor = '#92c93778'
  }

  return {
    backgroundColor: bgColor,
  }
})

export const NominationsTableCalendar: React.FC = () => {
  const {setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {setData: setNewSelectedDate} = useBucketStore('selectedDate')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const [previousSelectedBps, setPreviousSelectedBps] = useState<number>(selectedBpsId ? selectedBpsId : -1)

  const [dateFrom, setDateFrom] = useState<string>(dayjs().date(1).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState<string>(dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD'))
  const [minMaxNomminationsLoaded, setMinMaxNomminationsLoaded] = useState<boolean>(false)

  const {run: runGetBpsNominationsMinMax} = useAsyncMethodWithErrorHandling(getBpsNominationsMinMax)

  const [bpsNominationsMinimumMaximum, setBpsNominationsMinMax] = useState<BpsNominationsMinimumAndMaximum[]>([])

  const fetchBpsNominationsMinimumMaximum = React.useCallback(
    async (bpsIdParam: number) => {
      const result = (await runGetBpsNominationsMinMax({bpsId: bpsIdParam, dateFrom, dateTo})).data
      setBpsNominationsMinMax(result?.nominations ?? [])
    },
    [runGetBpsNominationsMinMax, setBpsNominationsMinMax, dateFrom, dateTo],
  )

  useEffect(() => {
    if (selectedBpsId && !minMaxNomminationsLoaded) {
      fetchBpsNominationsMinimumMaximum(selectedBpsId)
    } else if (selectedBpsId && selectedBpsId != previousSelectedBps) {
      // for BPS switching in calendar mode
      setPreviousSelectedBps(selectedBpsId)
      fetchBpsNominationsMinimumMaximum(selectedBpsId)
      setMinMaxNomminationsLoaded(true)
    }
  }, [previousSelectedBps, selectedBpsId, fetchBpsNominationsMinimumMaximum, minMaxNomminationsLoaded])

  return (
    <>
      <CustomCalendarPicker
        onChange={(newValue) => {
          if (newValue) {
            setNewSelectedDate(dayjs(newValue as Date).format('YYYY-MM-DD'))
            setBpsNominationTableOpen(true)
          }
        }}
        onMonthChange={(value) => {
          setDateFrom(
            dayjs(value as Date)
              .date(1)
              .format('YYYY-MM-DD'),
          )
          setDateTo(
            dayjs(value as Date)
              .date(dayjs(value as Date).daysInMonth())
              .format('YYYY-MM-DD'),
          )
          setMinMaxNomminationsLoaded(false)
        }}
        date={undefined}
        renderDay={(day, selectedDays, pickersDayProps) => {
          const bpsNominationMinMax = bpsNominationsMinimumMaximum.filter(
            (item) => item.date === dayjs(day as Date).format('YYYY-MM-DD'),
          )

          const color = bpsNominationMinMax[0]?.value_color ?? 'red'

          return (
            <StyledPickersDay {...pickersDayProps} color={color}>
              <Tooltip
                title={
                  // using div style for \n separation
                  <div style={{whiteSpace: 'pre-line'}}>
                    {`MIN: ${transformToKwWithEndKw(bpsNominationMinMax[0]?.min_value)}
                      MAX: ${transformToKwWithEndKw(bpsNominationMinMax[0]?.max_value)}`}
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span>{dayjs(day as Date).date()}</span>
                </div>
              </Tooltip>
            </StyledPickersDay>
          )
        }}
        views={['day']}
        sx={{display: 'block'}}
      />
    </>
  )
}
