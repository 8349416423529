import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {Profile} from '../api/generated'

type ConfirmUseProfileDialogProps = {
  open: boolean
  onClose: () => void
  dates: string[]
  profile: Profile | null
  confirm: (confirmed: boolean) => void
  confirmButtonDisabled: boolean
}

export const ConfirmUseProfileDialog: React.FC<ConfirmUseProfileDialogProps> = ({
  open,
  onClose,
  dates,
  profile,
  confirm,
  confirmButtonDisabled,
}) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Použiť profil</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              <p>
                Želáte si použiť profil&nbsp;
                <strong>{profile?.name}</strong> pre nominácie na dni:&nbsp;
              </p>
              <p>
                <strong>{dates.map((date) => dayjs(date).format('DD.MM.YYYY')).join(', ')}</strong>?
              </p>
              <p>Existujúce nominácie na dané dni budú prepísané.</p>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={() => confirm(true)} variant="contained" disabled={confirmButtonDisabled}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
