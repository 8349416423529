import {useNavigate} from 'react-router'
import {absolutePath} from '../navigation/utils'
import {useAsyncMethod} from './useAsyncMethod'
import {useBucketStoreContext} from '../utils/BucketStoreContext'

type AsyncResult<ResponseData> = {
  data: ResponseData | undefined
  error: unknown
}

type UseAsyncMethodReturn<ResponseData, Params = undefined> = {
  run: (params: Params) => Promise<AsyncResult<ResponseData>>
  data: ResponseData | undefined
  error: unknown | undefined
  loading: boolean
  reset: () => void
}

/**
 * @param asyncMethod should be a memoized method (e.g. wrapped in `React.useCallback`), or an imported function with a constant reference
 */
export const useAsyncMethodWithErrorHandling = <ResponseData, Params = void>(
  asyncMethod: (params: Params) => Promise<ResponseData>,
): UseAsyncMethodReturn<ResponseData, Params> => {
  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const asynchMethodResult = useAsyncMethod(asyncMethod)

  if (asynchMethodResult.error) {
    // eslint-disable-next-line
    if ((asynchMethodResult.error as any)?.response?.status == 401) {
      sessionStorage.clear()
      clearAllBuckets()
      navigation(absolutePath('login'))
    }
  }

  return asynchMethodResult
}
