import {NominationBase, NominationFull} from '../types/nomination'
import {NominationExtendedFull} from '../types/nominationExtended'

export const checkOverlap = (x: NominationBase, nomination: NominationBase) => {
  if (!nomination.time_from || !nomination.time_to) {
    return false
  }
  if (!x.time_to || !x.time_from) {
    return false
  }
  const noOverlap = x.time_to <= nomination.time_from || x.time_from >= nomination.time_to
  return !noOverlap
}

export const merge = (nominations: NominationFull[]) => {
  const result: NominationFull[] = []

  let wip: NominationFull | undefined = undefined

  nominations.forEach((x) => {
    if (wip == null) {
      wip = x
      return
    }

    const canMerge =
      wip.time_to === x.time_from &&
      wip.bps_id === x.bps_id &&
      wip.value_percentage === x.value_percentage &&
      wip.value_mw === x.value_mw &&
      (wip.note || undefined) === (x.note || undefined)
    if (canMerge) {
      wip = {...wip, time_to: x.time_to}
      // rest of x is ignored
    } else {
      result.push(wip)
      wip = x
    }
  })

  if (wip != null) {
    result.push(wip)
    wip = undefined
  }
  return result
}

export const mergeExtended = (nominations: NominationExtendedFull[]) => {
  const merged: NominationExtendedFull[] = []

  let tempNomination: NominationExtendedFull | undefined = undefined

  nominations.forEach((nomination) => {
    if (tempNomination == null) {
      tempNomination = nomination
      return
    }

    const canMerge =
      tempNomination.time_to === nomination.time_from &&
      tempNomination.bps_id === nomination.bps_id &&
      tempNomination.value_percentage === nomination.value_percentage &&
      tempNomination.value_mw === nomination.value_mw &&
      tempNomination.p_reg_min_mw === nomination.p_reg_min_mw &&
      tempNomination.p_reg_max_mw === nomination.p_reg_max_mw &&
      (tempNomination.note ?? undefined) === (nomination.note ?? undefined)

    if (canMerge) {
      tempNomination = {...tempNomination, time_to: nomination.time_to}
      // rest of notification is ignored
    } else {
      merged.push(tempNomination)
      tempNomination = nomination
    }
  })

  if (tempNomination != null) {
    merged.push(tempNomination)
    tempNomination = undefined
  }
  return merged
}
