import React, {useState, useEffect} from 'react'
import {
  BpsCounterImbalance,
  BpsPriceParams,
  BpsRemoteControl,
  BpsRemoteControlCtrlPwrRngModeEnum,
} from '../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {EditCounterImbalanceDialog} from '../EditCounterImbalanceDialog'
import {EditBpsPriceParamsDialog} from '../EditBpsPriceParamsDialog'
import {EditBpsRemoteControlDialog} from '../EditBpsRemoteControlDialog'
import {getCounterImbalanceForBps, getPriceParamsForBps, getRemoteControlForBps} from '../../api/bpsApi'
import {CounterImbalanceTable} from './Operation/CounterImbalanceTable'
import {BpsPriceParamsTable} from './Operation/BpsPriceParamsTable'
import {BpsRemoteControlTable} from './Operation/BpsRemoteControlTable'
import {CONTROL_POWER_RANGE_MODE_TYPES} from '../../utils/constants'

export const OperationView: React.FC = () => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const [checkBoxEnableCI, setCheckBoxEnableCI] = React.useState<boolean>(true)
  const [checkBoxBpsSupportsPayoff, setCheckBoxBpsSupportsPayoff] = React.useState<boolean>(true)

  const [isEditCounterImbalanceDialogOpen, setIsEditCounterImbalanceDialogOpen] = useState<boolean>(false)
  const [isEditBpsPriceParamsDialogOpen, setIsEditBpsPriceParamsDialogOpen] = useState<boolean>(false)
  const [isEditBpsRemoteControlDialogOpen, setIsBpsEditRemoteControlDialogOpen] = useState<boolean>(false)

  const [bpsCounterImbalance, setBpsCounterImbalance] = useState<BpsCounterImbalance>()
  const [bpsPriceParams, setBpsPriceParams] = useState<BpsPriceParams>()
  const [bpsRemoteControl, setBpsRemoteControl] = useState<BpsRemoteControl>()

  const [checkBoxThreshold, setCheckBoxThreshold] = React.useState(false)
  const [checkBoxRemoteControl, setCheckBoxRemoteControl] = React.useState(false)
  const [forceKeepBspRcOffer, setForceKeepBspRcOffer] = React.useState(false)
  const [forceLimitedResponsivity, setForceLimitedResponsivity] = React.useState(false)
  const [controlPowerRangeModeType, setControlPowerRangeModeType] = React.useState(CONTROL_POWER_RANGE_MODE_TYPES[1])

  const {run: runGetCounterImbalanceForBps} = useAsyncMethodWithErrorHandling(getCounterImbalanceForBps)
  const {run: runGetPriceParamsForBps} = useAsyncMethodWithErrorHandling(getPriceParamsForBps)
  const {run: runGetRemoteControlForBps} = useAsyncMethodWithErrorHandling(getRemoteControlForBps)

  const handleCloseEditCounterImbalanceDialog = () => {
    setIsEditCounterImbalanceDialogOpen(false)
  }

  const handleCloseEditBpsPriceParamsDialog = () => {
    setIsEditBpsPriceParamsDialogOpen(false)
  }

  const handleCloseBpsRemoteControlDialog = () => {
    setIsBpsEditRemoteControlDialogOpen(false)
  }

  const fetchBpsCounterImbalance = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetCounterImbalanceForBps({bpsId: selectedBpsId})).data

      setBpsCounterImbalance(result)
      setCheckBoxEnableCI(result?.counter_imbalance_enabled == undefined ? false : result.counter_imbalance_enabled)
    }
  }, [runGetCounterImbalanceForBps, selectedBpsId])

  const fetchBpsPriceParams = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetPriceParamsForBps({bpsId: selectedBpsId})).data

      setBpsPriceParams(result)
      setCheckBoxBpsSupportsPayoff(result?.supports_payoff == undefined ? false : result.supports_payoff)
    }
  }, [runGetPriceParamsForBps, selectedBpsId])

  const fetchBpsRemoteControl = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetRemoteControlForBps({bpsId: selectedBpsId})).data

      setBpsRemoteControl(result)

      setCheckBoxThreshold((c) => (result?.control_at_threshold == undefined ? c : result.control_at_threshold))
      setCheckBoxRemoteControl((c) => (result?.rc_enabled == undefined ? c : result.rc_enabled))
      setForceKeepBspRcOffer((c) => (result?.force_keep_bsp_rc_offer == undefined ? c : result.force_keep_bsp_rc_offer))
      setForceLimitedResponsivity((c) =>
        result?.force_limited_responsivity == undefined ? c : result.force_limited_responsivity,
      )
      setControlPowerRangeModeType((c) =>
        result?.ctrl_pwr_rng_mode == undefined
          ? c
          : CONTROL_POWER_RANGE_MODE_TYPES[
              Object.values(BpsRemoteControlCtrlPwrRngModeEnum).indexOf(result.ctrl_pwr_rng_mode)
            ],
      )
    }
  }, [runGetRemoteControlForBps, selectedBpsId])

  const handleCounterImbalanceUpdate = () => {
    setIsEditCounterImbalanceDialogOpen(false)
    fetchBpsCounterImbalance()
  }

  const handleBpsPriceParamsUpdate = () => {
    setIsEditBpsPriceParamsDialogOpen(false)
    fetchBpsPriceParams()
  }

  const handleBpsRemoteControlUpdate = () => {
    setIsBpsEditRemoteControlDialogOpen(false)
    fetchBpsRemoteControl()
  }

  useEffect(() => {
    fetchBpsCounterImbalance()
    fetchBpsPriceParams()
    fetchBpsRemoteControl()
  }, [fetchBpsCounterImbalance, fetchBpsPriceParams, fetchBpsRemoteControl])

  return (
    <>
      <CounterImbalanceTable
        setIsEditCounterImbalanceDialogOpen={setIsEditCounterImbalanceDialogOpen}
        bpsCounterImbalance={bpsCounterImbalance}
        checkBoxEnableCI={checkBoxEnableCI}
      />

      <BpsPriceParamsTable
        setIsEditBpsPriceParamsDialogOpen={setIsEditBpsPriceParamsDialogOpen}
        bpsPriceParams={bpsPriceParams}
        checkBoxBpsSupportsPayoff={checkBoxBpsSupportsPayoff}
      />

      <BpsRemoteControlTable
        setIsBpsEditRemoteControlDialogOpen={setIsBpsEditRemoteControlDialogOpen}
        checkBoxThreshold={checkBoxThreshold}
        checkBoxRemoteControl={checkBoxRemoteControl}
        forceKeepBspRcOffer={forceKeepBspRcOffer}
        forceLimitedResponsivity={forceLimitedResponsivity}
        controlPowerRangeModeType={controlPowerRangeModeType}
      />

      {isEditCounterImbalanceDialogOpen && (
        <EditCounterImbalanceDialog
          open={isEditCounterImbalanceDialogOpen}
          onClose={handleCloseEditCounterImbalanceDialog}
          onUpdate={handleCounterImbalanceUpdate}
          counterImbalance={bpsCounterImbalance}
        />
      )}

      {isEditBpsPriceParamsDialogOpen && (
        <EditBpsPriceParamsDialog
          open={isEditBpsPriceParamsDialogOpen}
          onClose={handleCloseEditBpsPriceParamsDialog}
          onUpdate={handleBpsPriceParamsUpdate}
          bpsPriceParams={bpsPriceParams}
        />
      )}

      {isEditBpsRemoteControlDialogOpen && (
        <EditBpsRemoteControlDialog
          open={isEditBpsRemoteControlDialogOpen}
          onClose={handleCloseBpsRemoteControlDialog}
          onUpdate={handleBpsRemoteControlUpdate}
          bpsRemoteControl={bpsRemoteControl}
        />
      )}
    </>
  )
}
