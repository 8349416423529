import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers'
import dayjs, {Dayjs} from 'dayjs'
import React, {useState} from 'react'
import {duplicateNominationV2, getGroupNominations, getNominationsV2} from '../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../utils/BucketStoreContext'
import {ConfirmCopyDayDialog} from './ConfirmCopyDayDialog'

type CopyDayDialogProps = {
  open: boolean
  onClose: () => void
  selectedTab: string
}

export const CopyDayDialog: React.FC<CopyDayDialogProps> = ({open, onClose, selectedTab}) => {
  const [dateSource, setDateSource] = useState<string | null>(null)
  const [dateTarget, setDateTarget] = useState<string | null>(null)
  const [isCopyDayDialogOpen, setIsCopyDayDialogOpen] = useState<boolean>(false)
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedDate, setData: setSelectedDate} = useBucketStore('selectedDate')
  const {setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {setData: setNominationsExtended} = useBucketStore('nominationsExtended')
  const {setData: setGroupNominations} = useBucketStore('groupNominations')

  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runGetGroupNominations} = useAsyncMethodWithErrorHandling(getGroupNominations)

  const fetchNominations = React.useCallback(
    async (date: string) => {
      if (selectedBpsId) {
        const normalizedDate = date.substring(0, 10)
        const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

        setNominationsExtended(result?.nominations ?? [])
      }
    },
    [selectedBpsId, runGetNominationsV2, setNominationsExtended],
  )

  const fetchGroupNominations = React.useCallback(
    async (date: string) => {
      if (selectedBpsId) {
        const normalizedDate = date.substring(0, 10)
        const result = (await runGetGroupNominations({date: normalizedDate})).data

        setGroupNominations(result?.calculated ?? [])
      }
    },
    [selectedBpsId, runGetGroupNominations, setGroupNominations],
  )

  const handleConfirm = async () => {
    setIsConfirmDisabled(true)
    if (!selectedBpsId || !dateSource || !dateTarget) {
      return
    }
    const dateSourceNoTime = dateSource.substring(0, 10)
    const dateTargetNoTime = dateTarget.substring(0, 10)
    try {
      await duplicateNominationV2({
        bps_id: selectedBpsId,
        date_from: dateSourceNoTime,
        date_to: dateTargetNoTime,
      })

      const selectedDateNoTime = selectedDate.substring(0, 10)

      if (selectedDateNoTime === dateTargetNoTime) {
        // refetch
        if (selectedBpsId) {
          fetchNominations(selectedDateNoTime)
          fetchGroupNominations(selectedDateNoTime)
        }
      } else {
        setSelectedDate(dateTarget)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    // bpsNominationTableOpen will not open in the backround in when in graph-view
    if (selectedTab == 'table-view') {
      setBpsNominationTableOpen(true)
    }

    setIsCopyDayDialogOpen(false)
    setIsConfirmDisabled(false)
    onClose()
  }

  const isTheSameDate = (date: Dayjs, dateToCompareTo: string | null) => {
    return date.format('DD/MM/YYYY') === dayjs(dateToCompareTo).format('DD/MM/YYYY')
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Kopírovať nominácie dňa</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" marginY={2}>
            <Typography>Kopírovať z:</Typography>
            <MobileDatePicker<string, Dayjs>
              value={dateSource}
              onChange={(newValue) => {
                setDateSource(newValue?.format('YYYY-MM-DDTHH:mm:ss') ?? null)
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              componentsProps={{actionBar: {actions: ['cancel', 'accept']}}}
              shouldDisableDate={(date: Dayjs) => isTheSameDate(date, dateTarget)}
              showToolbar={false}
              onAccept={() => setIsConfirmDisabled(!dateSource || !dateTarget)}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" marginY={2}>
            <Typography>Kopírovať na:</Typography>
            <MobileDatePicker<string, Dayjs>
              value={dateTarget}
              onChange={(newValue) => {
                setDateTarget(newValue?.format('YYYY-MM-DDTHH:mm:ss') ?? null)
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              componentsProps={{actionBar: {actions: ['cancel', 'accept']}}}
              minDate={dayjs()}
              shouldDisableDate={(date: Dayjs) => isTheSameDate(date, dateSource)}
              showToolbar={false}
              onAccept={() => setIsConfirmDisabled(!dateSource || !dateTarget)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={() => setIsCopyDayDialogOpen(true)} variant="contained" disabled={isConfirmDisabled}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmCopyDayDialog
        open={isCopyDayDialogOpen}
        onClose={() => setIsCopyDayDialogOpen(false)}
        dateFrom={dateSource}
        dateTo={dateTarget}
        onConfirm={handleConfirm}
        confirmButtonDisabled={isConfirmDisabled}
      />
    </>
  )
}
