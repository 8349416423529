import {getConfiguration} from './configuration'
import {
  BpsControllerApi,
  BpsControllerApiGetBpsCounterImbalanceRequest,
  BpsControllerApiGetBpsDetailRequest,
  BpsControllerApiGetBpsDetailV2Request,
  BpsControllerApiGetBpsRemoteControlRequest,
  BpsControllerApiUpdateBpsCounterImbalanceRequest,
  BpsControllerApiUpdateBpsParametersV2Request,
  BpsControllerApiUpdateBpsRemoteControlRequest,
  BpsControllerApiUpdateGasRequest,
  BpsControllerApiUpdateDeviceRequest,
  BpsControllerApiGetDevicesForBpsRequest,
  BpsControllerApiGetBpsPriceParamsRequest,
  BpsControllerApiUpdateBpsPriceParamsRequest,
  BpsControllerApiResetSlcControlPowerRangeRequest,
} from './generated'

export const getBpsDetails = async (params: BpsControllerApiGetBpsDetailRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getBpsDetail(params)).data
}

export const getBpsDetailsV2 = async (params: BpsControllerApiGetBpsDetailV2Request) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getBpsDetailV2(params)).data
}

export const updateBpsV2 = async (params: BpsControllerApiUpdateBpsParametersV2Request) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.updateBpsParametersV2(params)).data
}

export const getAllBps = async () => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getAllBpsForView()).data
}

export const getDevicesForBps = async (params: BpsControllerApiGetDevicesForBpsRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getDevicesForBps(params)).data
}

export const updateDeviceForBps = async (params: BpsControllerApiUpdateDeviceRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.updateDevice(params)).data
}

export const updateGasForBps = async (params: BpsControllerApiUpdateGasRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.updateGas(params)).data
}

export const getCounterImbalanceForBps = async (params: BpsControllerApiGetBpsCounterImbalanceRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getBpsCounterImbalance(params)).data
}

export const updateCounterImbalanceForBps = async (params: BpsControllerApiUpdateBpsCounterImbalanceRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.updateBpsCounterImbalance(params)).data
}

export const getRemoteControlForBps = async (params: BpsControllerApiGetBpsRemoteControlRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getBpsRemoteControl(params)).data
}

export const updateRemoteControlForBps = async (params: BpsControllerApiUpdateBpsRemoteControlRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.updateBpsRemoteControl(params)).data
}

export const getPriceParamsForBps = async (params: BpsControllerApiGetBpsPriceParamsRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.getBpsPriceParams(params)).data
}

export const updatePriceParamsForBps = async (params: BpsControllerApiUpdateBpsPriceParamsRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.updateBpsPriceParams(params)).data
}

export const resetSlcControlPowerRange = async (params: BpsControllerApiResetSlcControlPowerRangeRequest) => {
  const configuration = getConfiguration()
  const api = new BpsControllerApi(configuration)

  return (await api.resetSlcControlPowerRange(params)).data
}
