import {NominationBase} from '../types/nomination'
import {getConfiguration} from './configuration'
import {
  DuplicateNominationRequest,
  NominationControllerApi,
  NominationControllerApiGetEnergySupplyGroupRequest,
  NominationControllerApiGetEnergySupplyRequest,
  NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest,
  NominationControllerApiGetGroupNominationsRequest,
  NominationControllerApiGetNominationsRequest,
  NominationControllerApiGetNominationsMinAndMaxRequest,
  NominationControllerApiGetGroupNominationsPeriodRequest,
  NominationControllerApiSendGroupNominationsV2Request,
  NominationControllerApiSendNominationsToAggregatorRequest,
  NominationControllerApiUpdateAllNominationOverrideRequest,
} from './generated'
import {NominationExtendedBase} from '../types/nominationExtended'

export const getNominations = async (params: NominationControllerApiGetNominationsRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getNominations(params)).data
}

export const getNominationsV2 = async (params: NominationControllerApiGetNominationsRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getNominationsV2(params)).data
}

export const getGroupNominations = async (params: NominationControllerApiGetGroupNominationsRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getGroupNominations(params)).data
}

export const getGroupNominationsMinimumAndMaximum = async (
  params: NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest,
) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getGroupNominationsMinimumAndMaximum(params)).data
}

export const createNomination = async (nomination: NominationBase) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.createNomination({nomination: nomination})).data
}

export const createNominationV2 = async (nomination: NominationExtendedBase) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.createNominationV2({nominationExtended: nomination})).data
}

export const duplicateNomination = async (params: DuplicateNominationRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.duplicateNominations({duplicateNominationRequest: params})).data
}

export const duplicateNominationV2 = async (params: DuplicateNominationRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.duplicateNominationsV2({duplicateNominationRequest: params})).data
}

export const getEnergySupply = async (params: NominationControllerApiGetEnergySupplyRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getEnergySupply(params)).data
}

export const getGroupEnergySupply = async (params: NominationControllerApiGetEnergySupplyGroupRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getEnergySupplyGroup(params)).data
}

export const sendGroupNominationsV2 = async (params: NominationControllerApiSendGroupNominationsV2Request) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.sendGroupNominationsV2(params)).data
}

export const sendGroupNominationsV3 = async (params: NominationControllerApiSendGroupNominationsV2Request) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.sendGroupNominationsV3(params)).data
}

export const getBpsNominationsMinMax = async (params: NominationControllerApiGetNominationsMinAndMaxRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getNominationsMinAndMax(params)).data
}

export const getGroupNominationsPeriod = async (params: NominationControllerApiGetGroupNominationsPeriodRequest) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getGroupNominationsPeriod(params)).data
}

export const sendNominationsToAggregator = async (
  params: NominationControllerApiSendNominationsToAggregatorRequest,
) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.sendNominationsToAggregator(params)).data
}

export const getProductionStatusEnum = async () => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getProductionStatusEnum()).data
}

export const getAllNominationOverride = async () => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.getAllNominationOverride()).data
}

export const updateAllNominationOverride = async (
  params: NominationControllerApiUpdateAllNominationOverrideRequest,
) => {
  const configuration = getConfiguration()
  const api = new NominationControllerApi(configuration)

  return (await api.updateAllNominationOverride(params)).data
}
