import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Button, Menu, MenuItem} from '@mui/material'
import React, {useEffect, useState} from 'react'

import {Bps as BpsGenerated} from '../../api/generated'

import {Bps, useBucketStore} from '../../utils/BucketStoreContext'
import {getAllBps} from '../../api/bpsApi'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'

export const BpsSelector: React.FC = () => {
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId, setData: setSelectedBpsId} = useBucketStore('selectedBpsId')

  const [providersMenuAnchorEl, setProvidersMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [bpsList, setBpsList] = useState<BpsGenerated[]>([])
  const [isBpsListLoaded, setIsBpsListLoaded] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<Bps>()

  const {run: runGetAllBps} = useAsyncMethodWithErrorHandling(getAllBps)

  const fetchBpsList = React.useCallback(async () => {
    const result = (await runGetAllBps()).data
    const allBps = result?.all_bps_for_view ?? []
    setBpsList(allBps)
  }, [runGetAllBps])

  const handleCloseProvidersMenu = () => {
    setProvidersMenuAnchorEl(null)
  }

  const handleProvidersMenuItemClick = (value: number | undefined) => () => {
    setSelectedBpsId(value)
    setProvidersMenuAnchorEl(null)
  }

  useEffect(() => {
    // check selectedBpsId for undefined for this fetch to be called only once (first loading)
    if (!isBpsListLoaded && typeof selectedBpsId !== 'undefined') {
      setIsBpsListLoaded(true)
      fetchBpsList()
    }

    const userInfoBps = userInfo?.bps
    if (userInfoBps == null || userInfoBps.length === 0) {
      return
    }

    let bpsIdMatch: Bps | undefined = undefined
    if (selectedBpsId != null) {
      bpsIdMatch = bpsList.find((x) => x.id == selectedBpsId) as Bps
    }

    if (!bpsIdMatch) {
      bpsIdMatch = userInfoBps[0]
    }

    setSelectedItem(bpsIdMatch)
    setSelectedBpsId(bpsIdMatch.id)
  }, [bpsList, selectedBpsId, setSelectedBpsId, userInfo?.bps, isBpsListLoaded, fetchBpsList])

  return (
    <>
      <Button
        onClick={(e) => setProvidersMenuAnchorEl(e.currentTarget)}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
        sx={{width: '305px'}}
      >
        {selectedItem ? selectedItem.name : '-'}
      </Button>
      <Menu anchorEl={providersMenuAnchorEl} open={!!providersMenuAnchorEl} onClose={handleCloseProvidersMenu}>
        {bpsList.map((provider) => (
          <MenuItem key={provider.id} onClick={handleProvidersMenuItemClick(provider.id)}>
            {provider.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
