/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BpsCounterImbalance } from '../model';
// @ts-ignore
import { BpsPriceParams } from '../model';
// @ts-ignore
import { BpsRemoteControl } from '../model';
// @ts-ignore
import { GetAllBpsForViewResponse } from '../model';
// @ts-ignore
import { GetBpsDetailResponse } from '../model';
// @ts-ignore
import { GetBpsDetailResponseExtended } from '../model';
// @ts-ignore
import { GetDevicesForBpsResponse } from '../model';
// @ts-ignore
import { NominationOverride } from '../model';
// @ts-ignore
import { UpdateBpsCounterImbalanceRequest } from '../model';
// @ts-ignore
import { UpdateBpsParameterRequest } from '../model';
// @ts-ignore
import { UpdateBpsParametersExtendedRequest } from '../model';
// @ts-ignore
import { UpdateBpsPriceParamsRequest } from '../model';
// @ts-ignore
import { UpdateBpsRemoteControlRequest } from '../model';
// @ts-ignore
import { UpdateDeviceRequest } from '../model';
// @ts-ignore
import { UpdateGasRequest } from '../model';
/**
 * BpsControllerApi - axios parameter creator
 * @export
 */
export const BpsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBpsForView: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bps/view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsCounterImbalance: async (bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getBpsCounterImbalance', 'bpsId', bpsId)
            const localVarPath = `/api/bps/bps-counter-imbalance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bpsId'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsDetail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBpsDetail', 'id', id)
            const localVarPath = `/api/bps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsDetailV2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBpsDetailV2', 'id', id)
            const localVarPath = `/api/v2/bps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsPriceParams: async (bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getBpsPriceParams', 'bpsId', bpsId)
            const localVarPath = `/api/bps/bps-price-params`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsRemoteControl: async (bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getBpsRemoteControl', 'bpsId', bpsId)
            const localVarPath = `/api/bps/bps-remote-control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bpsId'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesForBps: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDevicesForBps', 'id', id)
            const localVarPath = `/api/bps/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSlcControlPowerRange: async (bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('resetSlcControlPowerRange', 'bpsId', bpsId)
            const localVarPath = `/api/bps/{bpsId}/slc-control-power-range/reset`
                .replace(`{${"bpsId"}}`, encodeURIComponent(String(bpsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBpsCounterImbalanceRequest} updateBpsCounterImbalanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsCounterImbalance: async (updateBpsCounterImbalanceRequest: UpdateBpsCounterImbalanceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBpsCounterImbalanceRequest' is not null or undefined
            assertParamExists('updateBpsCounterImbalance', 'updateBpsCounterImbalanceRequest', updateBpsCounterImbalanceRequest)
            const localVarPath = `/api/bps/bps-counter-imbalance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBpsCounterImbalanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBpsParameterRequest} updateBpsParameterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsParameter: async (id: number, updateBpsParameterRequest: UpdateBpsParameterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBpsParameter', 'id', id)
            // verify required parameter 'updateBpsParameterRequest' is not null or undefined
            assertParamExists('updateBpsParameter', 'updateBpsParameterRequest', updateBpsParameterRequest)
            const localVarPath = `/api/bps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBpsParameterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBpsParametersExtendedRequest} updateBpsParametersExtendedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsParametersV2: async (id: number, updateBpsParametersExtendedRequest: UpdateBpsParametersExtendedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBpsParametersV2', 'id', id)
            // verify required parameter 'updateBpsParametersExtendedRequest' is not null or undefined
            assertParamExists('updateBpsParametersV2', 'updateBpsParametersExtendedRequest', updateBpsParametersExtendedRequest)
            const localVarPath = `/api/v2/bps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBpsParametersExtendedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBpsPriceParamsRequest} updateBpsPriceParamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsPriceParams: async (updateBpsPriceParamsRequest: UpdateBpsPriceParamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBpsPriceParamsRequest' is not null or undefined
            assertParamExists('updateBpsPriceParams', 'updateBpsPriceParamsRequest', updateBpsPriceParamsRequest)
            const localVarPath = `/api/bps/bps-price-params`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBpsPriceParamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBpsRemoteControlRequest} updateBpsRemoteControlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsRemoteControl: async (updateBpsRemoteControlRequest: UpdateBpsRemoteControlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBpsRemoteControlRequest' is not null or undefined
            assertParamExists('updateBpsRemoteControl', 'updateBpsRemoteControlRequest', updateBpsRemoteControlRequest)
            const localVarPath = `/api/bps/bps-remote-control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBpsRemoteControlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDeviceRequest} updateDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice: async (id: number, updateDeviceRequest: UpdateDeviceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDevice', 'id', id)
            // verify required parameter 'updateDeviceRequest' is not null or undefined
            assertParamExists('updateDevice', 'updateDeviceRequest', updateDeviceRequest)
            const localVarPath = `/api/bps/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateGasRequest} updateGasRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGas: async (id: number, updateGasRequest: UpdateGasRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGas', 'id', id)
            // verify required parameter 'updateGasRequest' is not null or undefined
            assertParamExists('updateGas', 'updateGasRequest', updateGasRequest)
            const localVarPath = `/api/bps/{id}/gas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGasRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BpsControllerApi - functional programming interface
 * @export
 */
export const BpsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BpsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBpsForView(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllBpsForViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBpsForView(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpsCounterImbalance(bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BpsCounterImbalance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpsCounterImbalance(bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpsDetail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBpsDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpsDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpsDetailV2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBpsDetailResponseExtended>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpsDetailV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpsPriceParams(bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BpsPriceParams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpsPriceParams(bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpsRemoteControl(bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BpsRemoteControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpsRemoteControl(bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesForBps(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDevicesForBpsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesForBps(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetSlcControlPowerRange(bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NominationOverride>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetSlcControlPowerRange(bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBpsCounterImbalanceRequest} updateBpsCounterImbalanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBpsCounterImbalance(updateBpsCounterImbalanceRequest: UpdateBpsCounterImbalanceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BpsCounterImbalance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBpsCounterImbalance(updateBpsCounterImbalanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBpsParameterRequest} updateBpsParameterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBpsParameter(id: number, updateBpsParameterRequest: UpdateBpsParameterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBpsDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBpsParameter(id, updateBpsParameterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBpsParametersExtendedRequest} updateBpsParametersExtendedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBpsParametersV2(id: number, updateBpsParametersExtendedRequest: UpdateBpsParametersExtendedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBpsDetailResponseExtended>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBpsParametersV2(id, updateBpsParametersExtendedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBpsPriceParamsRequest} updateBpsPriceParamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBpsPriceParams(updateBpsPriceParamsRequest: UpdateBpsPriceParamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BpsPriceParams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBpsPriceParams(updateBpsPriceParamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBpsRemoteControlRequest} updateBpsRemoteControlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBpsRemoteControl(updateBpsRemoteControlRequest: UpdateBpsRemoteControlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BpsRemoteControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBpsRemoteControl(updateBpsRemoteControlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDeviceRequest} updateDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDevice(id: number, updateDeviceRequest: UpdateDeviceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDevicesForBpsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevice(id, updateDeviceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateGasRequest} updateGasRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGas(id: number, updateGasRequest: UpdateGasRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBpsDetailResponseExtended>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGas(id, updateGasRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BpsControllerApi - factory interface
 * @export
 */
export const BpsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BpsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBpsForView(options?: any): AxiosPromise<GetAllBpsForViewResponse> {
            return localVarFp.getAllBpsForView(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsCounterImbalance(bpsId: number, options?: any): AxiosPromise<BpsCounterImbalance> {
            return localVarFp.getBpsCounterImbalance(bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsDetail(id: number, options?: any): AxiosPromise<GetBpsDetailResponse> {
            return localVarFp.getBpsDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsDetailV2(id: number, options?: any): AxiosPromise<GetBpsDetailResponseExtended> {
            return localVarFp.getBpsDetailV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsPriceParams(bpsId: number, options?: any): AxiosPromise<BpsPriceParams> {
            return localVarFp.getBpsPriceParams(bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsRemoteControl(bpsId: number, options?: any): AxiosPromise<BpsRemoteControl> {
            return localVarFp.getBpsRemoteControl(bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesForBps(id: number, options?: any): AxiosPromise<GetDevicesForBpsResponse> {
            return localVarFp.getDevicesForBps(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSlcControlPowerRange(bpsId: number, options?: any): AxiosPromise<NominationOverride> {
            return localVarFp.resetSlcControlPowerRange(bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBpsCounterImbalanceRequest} updateBpsCounterImbalanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsCounterImbalance(updateBpsCounterImbalanceRequest: UpdateBpsCounterImbalanceRequest, options?: any): AxiosPromise<BpsCounterImbalance> {
            return localVarFp.updateBpsCounterImbalance(updateBpsCounterImbalanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBpsParameterRequest} updateBpsParameterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsParameter(id: number, updateBpsParameterRequest: UpdateBpsParameterRequest, options?: any): AxiosPromise<GetBpsDetailResponse> {
            return localVarFp.updateBpsParameter(id, updateBpsParameterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBpsParametersExtendedRequest} updateBpsParametersExtendedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsParametersV2(id: number, updateBpsParametersExtendedRequest: UpdateBpsParametersExtendedRequest, options?: any): AxiosPromise<GetBpsDetailResponseExtended> {
            return localVarFp.updateBpsParametersV2(id, updateBpsParametersExtendedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBpsPriceParamsRequest} updateBpsPriceParamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsPriceParams(updateBpsPriceParamsRequest: UpdateBpsPriceParamsRequest, options?: any): AxiosPromise<BpsPriceParams> {
            return localVarFp.updateBpsPriceParams(updateBpsPriceParamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBpsRemoteControlRequest} updateBpsRemoteControlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBpsRemoteControl(updateBpsRemoteControlRequest: UpdateBpsRemoteControlRequest, options?: any): AxiosPromise<BpsRemoteControl> {
            return localVarFp.updateBpsRemoteControl(updateBpsRemoteControlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDeviceRequest} updateDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(id: number, updateDeviceRequest: UpdateDeviceRequest, options?: any): AxiosPromise<GetDevicesForBpsResponse> {
            return localVarFp.updateDevice(id, updateDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateGasRequest} updateGasRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGas(id: number, updateGasRequest: UpdateGasRequest, options?: any): AxiosPromise<GetBpsDetailResponseExtended> {
            return localVarFp.updateGas(id, updateGasRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBpsCounterImbalance operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiGetBpsCounterImbalanceRequest
 */
export interface BpsControllerApiGetBpsCounterImbalanceRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiGetBpsCounterImbalance
     */
    readonly bpsId: number
}

/**
 * Request parameters for getBpsDetail operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiGetBpsDetailRequest
 */
export interface BpsControllerApiGetBpsDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiGetBpsDetail
     */
    readonly id: number
}

/**
 * Request parameters for getBpsDetailV2 operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiGetBpsDetailV2Request
 */
export interface BpsControllerApiGetBpsDetailV2Request {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiGetBpsDetailV2
     */
    readonly id: number
}

/**
 * Request parameters for getBpsPriceParams operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiGetBpsPriceParamsRequest
 */
export interface BpsControllerApiGetBpsPriceParamsRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiGetBpsPriceParams
     */
    readonly bpsId: number
}

/**
 * Request parameters for getBpsRemoteControl operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiGetBpsRemoteControlRequest
 */
export interface BpsControllerApiGetBpsRemoteControlRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiGetBpsRemoteControl
     */
    readonly bpsId: number
}

/**
 * Request parameters for getDevicesForBps operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiGetDevicesForBpsRequest
 */
export interface BpsControllerApiGetDevicesForBpsRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiGetDevicesForBps
     */
    readonly id: number
}

/**
 * Request parameters for resetSlcControlPowerRange operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiResetSlcControlPowerRangeRequest
 */
export interface BpsControllerApiResetSlcControlPowerRangeRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiResetSlcControlPowerRange
     */
    readonly bpsId: number
}

/**
 * Request parameters for updateBpsCounterImbalance operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateBpsCounterImbalanceRequest
 */
export interface BpsControllerApiUpdateBpsCounterImbalanceRequest {
    /**
     * 
     * @type {UpdateBpsCounterImbalanceRequest}
     * @memberof BpsControllerApiUpdateBpsCounterImbalance
     */
    readonly updateBpsCounterImbalanceRequest: UpdateBpsCounterImbalanceRequest
}

/**
 * Request parameters for updateBpsParameter operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateBpsParameterRequest
 */
export interface BpsControllerApiUpdateBpsParameterRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiUpdateBpsParameter
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBpsParameterRequest}
     * @memberof BpsControllerApiUpdateBpsParameter
     */
    readonly updateBpsParameterRequest: UpdateBpsParameterRequest
}

/**
 * Request parameters for updateBpsParametersV2 operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateBpsParametersV2Request
 */
export interface BpsControllerApiUpdateBpsParametersV2Request {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiUpdateBpsParametersV2
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBpsParametersExtendedRequest}
     * @memberof BpsControllerApiUpdateBpsParametersV2
     */
    readonly updateBpsParametersExtendedRequest: UpdateBpsParametersExtendedRequest
}

/**
 * Request parameters for updateBpsPriceParams operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateBpsPriceParamsRequest
 */
export interface BpsControllerApiUpdateBpsPriceParamsRequest {
    /**
     * 
     * @type {UpdateBpsPriceParamsRequest}
     * @memberof BpsControllerApiUpdateBpsPriceParams
     */
    readonly updateBpsPriceParamsRequest: UpdateBpsPriceParamsRequest
}

/**
 * Request parameters for updateBpsRemoteControl operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateBpsRemoteControlRequest
 */
export interface BpsControllerApiUpdateBpsRemoteControlRequest {
    /**
     * 
     * @type {UpdateBpsRemoteControlRequest}
     * @memberof BpsControllerApiUpdateBpsRemoteControl
     */
    readonly updateBpsRemoteControlRequest: UpdateBpsRemoteControlRequest
}

/**
 * Request parameters for updateDevice operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateDeviceRequest
 */
export interface BpsControllerApiUpdateDeviceRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiUpdateDevice
     */
    readonly id: number

    /**
     * 
     * @type {UpdateDeviceRequest}
     * @memberof BpsControllerApiUpdateDevice
     */
    readonly updateDeviceRequest: UpdateDeviceRequest
}

/**
 * Request parameters for updateGas operation in BpsControllerApi.
 * @export
 * @interface BpsControllerApiUpdateGasRequest
 */
export interface BpsControllerApiUpdateGasRequest {
    /**
     * 
     * @type {number}
     * @memberof BpsControllerApiUpdateGas
     */
    readonly id: number

    /**
     * 
     * @type {UpdateGasRequest}
     * @memberof BpsControllerApiUpdateGas
     */
    readonly updateGasRequest: UpdateGasRequest
}

/**
 * BpsControllerApi - object-oriented interface
 * @export
 * @class BpsControllerApi
 * @extends {BaseAPI}
 */
export class BpsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getAllBpsForView(options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getAllBpsForView(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiGetBpsCounterImbalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getBpsCounterImbalance(requestParameters: BpsControllerApiGetBpsCounterImbalanceRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getBpsCounterImbalance(requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiGetBpsDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getBpsDetail(requestParameters: BpsControllerApiGetBpsDetailRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getBpsDetail(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiGetBpsDetailV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getBpsDetailV2(requestParameters: BpsControllerApiGetBpsDetailV2Request, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getBpsDetailV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiGetBpsPriceParamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getBpsPriceParams(requestParameters: BpsControllerApiGetBpsPriceParamsRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getBpsPriceParams(requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiGetBpsRemoteControlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getBpsRemoteControl(requestParameters: BpsControllerApiGetBpsRemoteControlRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getBpsRemoteControl(requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiGetDevicesForBpsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public getDevicesForBps(requestParameters: BpsControllerApiGetDevicesForBpsRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).getDevicesForBps(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiResetSlcControlPowerRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public resetSlcControlPowerRange(requestParameters: BpsControllerApiResetSlcControlPowerRangeRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).resetSlcControlPowerRange(requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateBpsCounterImbalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateBpsCounterImbalance(requestParameters: BpsControllerApiUpdateBpsCounterImbalanceRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateBpsCounterImbalance(requestParameters.updateBpsCounterImbalanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateBpsParameterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateBpsParameter(requestParameters: BpsControllerApiUpdateBpsParameterRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateBpsParameter(requestParameters.id, requestParameters.updateBpsParameterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateBpsParametersV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateBpsParametersV2(requestParameters: BpsControllerApiUpdateBpsParametersV2Request, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateBpsParametersV2(requestParameters.id, requestParameters.updateBpsParametersExtendedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateBpsPriceParamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateBpsPriceParams(requestParameters: BpsControllerApiUpdateBpsPriceParamsRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateBpsPriceParams(requestParameters.updateBpsPriceParamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateBpsRemoteControlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateBpsRemoteControl(requestParameters: BpsControllerApiUpdateBpsRemoteControlRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateBpsRemoteControl(requestParameters.updateBpsRemoteControlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateDevice(requestParameters: BpsControllerApiUpdateDeviceRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateDevice(requestParameters.id, requestParameters.updateDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BpsControllerApiUpdateGasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BpsControllerApi
     */
    public updateGas(requestParameters: BpsControllerApiUpdateGasRequest, options?: AxiosRequestConfig) {
        return BpsControllerApiFp(this.configuration).updateGas(requestParameters.id, requestParameters.updateGasRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
