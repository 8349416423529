import {
  FinanceCalculationControllerApi,
  FinanceCalculationControllerApiCalculateFinancesRequest,
  FinanceCalculationControllerApiGetEnergyPricesRequest,
} from './generated'
import {getConfiguration} from './configuration'

export const getFinanceCalculation = async (params: FinanceCalculationControllerApiCalculateFinancesRequest) => {
  const configuration = getConfiguration()
  const api = new FinanceCalculationControllerApi(configuration)

  return (await api.calculateFinances(params)).data
}

export const getEnergyPrice = async (params: FinanceCalculationControllerApiGetEnergyPricesRequest) => {
  const configuration = getConfiguration()
  const api = new FinanceCalculationControllerApi(configuration)

  return (await api.getEnergyPrices(params)).data
}
