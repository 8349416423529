import React, {useEffect, useState} from 'react'
import {Button, Menu, MenuItem} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {Profile} from '../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {getProfiles} from '../../api/profilesApi'

export const ProfileSelector: React.FC = () => {
  const [providersMenuAnchorEl, setProvidersMenuAnchorEl] = useState<HTMLElement | null>(null)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: profiles, setData: setProfiles} = useBucketStore('profiles')
  const {data: selectedProfile, setData: setSelectedProfile} = useBucketStore('selectedProfile')

  const {run: runGetProfiles} = useAsyncMethodWithErrorHandling(getProfiles)

  const fetchData = React.useCallback(async () => {
    if (selectedBpsId) {
      const fetchProfilesResult = (await runGetProfiles({bpsId: selectedBpsId})).data
      setProfiles(fetchProfilesResult?.profiles ?? [])

      if (fetchProfilesResult?.profiles) {
        setSelectedProfile(fetchProfilesResult.profiles[0])
      }
    }
  }, [runGetProfiles, setProfiles, selectedBpsId, setSelectedProfile])

  const handleCloseProvidersMenu = () => {
    setProvidersMenuAnchorEl(null)
  }

  const handleProvidersMenuItemClick = (profile: Profile) => () => {
    setSelectedProfile(profile)
    setProvidersMenuAnchorEl(null)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <Button
        onClick={(e) => setProvidersMenuAnchorEl(e.currentTarget)}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
      >
        {selectedProfile ? selectedProfile.name : '-'}
      </Button>
      <Menu anchorEl={providersMenuAnchorEl} open={!!providersMenuAnchorEl} onClose={handleCloseProvidersMenu}>
        {profiles.map((profile) => (
          <MenuItem key={profile.id} onClick={handleProvidersMenuItemClick(profile)}>
            {profile.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
