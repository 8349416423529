import {
  ProfileControllerApi,
  ProfileControllerApiApplyProfileRequest,
  ProfileControllerApiCreateProfileRequest,
  ProfileControllerApiDeleteProfileRequest,
  ProfileControllerApiDeleteProfileV2Request,
  ProfileControllerApiGetProfileNominationsRequest,
  ProfileControllerApiGetProfilesRequest,
  ProfileControllerApiUpdateProfileRequest,
  ProfileControllerApiUpdateProfileV2Request,
} from './generated'
import {getConfiguration} from './configuration'

export const getProfiles = async (params: ProfileControllerApiGetProfilesRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.getProfiles(params)).data
}

export const getProfileNominations = async (params: ProfileControllerApiGetProfileNominationsRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.getProfileNominations(params)).data
}

export const getProfileNominationsV2 = async (params: ProfileControllerApiGetProfileNominationsRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.getProfileNominationsV2(params)).data
}

export const createProfile = async (params: ProfileControllerApiCreateProfileRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.createProfile(params)).data
}

export const createProfileV2 = async (params: ProfileControllerApiCreateProfileRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.createProfileV2(params)).data
}

export const deleteProfile = async (params: ProfileControllerApiDeleteProfileRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.deleteProfile(params)).data
}

export const deleteProfileV2 = async (params: ProfileControllerApiDeleteProfileV2Request) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.deleteProfileV2(params)).data
}

export const applyProfileV2 = async (params: ProfileControllerApiApplyProfileRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.applyProfileV2(params)).data
}

export const updateProfile = async (params: ProfileControllerApiUpdateProfileRequest) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.updateProfile(params)).data
}

export const updateProfileV2 = async (params: ProfileControllerApiUpdateProfileV2Request) => {
  const configuration = getConfiguration()
  const api = new ProfileControllerApi(configuration)

  return (await api.updateProfileV2(params)).data
}
