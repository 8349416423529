import jwt_decode, {JwtPayload} from 'jwt-decode'

export type AuthJwtPayload = {
  roles?: string[]
  bps?: {id: number; name: string}[]
} & JwtPayload

export const parseToken = (jwtEncoded: string) => {
  try {
    const parsed = jwt_decode<AuthJwtPayload>(jwtEncoded)
    return {payload: parsed}
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }
}

export const accessTokenToUserInfo = (accessToken: string | null) => {
  if (accessToken == null) {
    return null
  }

  const parsedToken = parseToken(accessToken)

  if (parsedToken == null) {
    return null
  }

  let bps = parsedToken.payload.bps
  if (bps == undefined) {
    bps = []
  } else {
    bps.sort((a, b) => (a.id > b.id ? 1 : -1))
  }

  return {
    bps: bps,
    roles: parsedToken.payload.roles ?? [],
    userId: Number(parsedToken.payload.iss) ?? -1,
    sessionStartedAt: parsedToken.payload.iat ?? -1,
    sessionExpiration: parsedToken.payload.exp ?? -1,
  }
}
