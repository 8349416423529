/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EnergyPriceResponse } from '../model';
// @ts-ignore
import { FinanceCalculationResponse } from '../model';
/**
 * FinanceCalculationControllerApi - axios parameter creator
 * @export
 */
export const FinanceCalculationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} bpsId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        calculateFinances: async (bpsId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('calculateFinances', 'bpsId', bpsId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('calculateFinances', 'date', date)
            const localVarPath = `/api/finance-calculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyPrices: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getEnergyPrices', 'date', date)
            const localVarPath = `/api/finance-calculation/energy-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinanceCalculationControllerApi - functional programming interface
 * @export
 */
export const FinanceCalculationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinanceCalculationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} bpsId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async calculateFinances(bpsId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinanceCalculationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFinances(bpsId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyPrices(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergyPriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergyPrices(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinanceCalculationControllerApi - factory interface
 * @export
 */
export const FinanceCalculationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinanceCalculationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} bpsId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        calculateFinances(bpsId: number, date: string, options?: any): AxiosPromise<FinanceCalculationResponse> {
            return localVarFp.calculateFinances(bpsId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyPrices(date: string, options?: any): AxiosPromise<EnergyPriceResponse> {
            return localVarFp.getEnergyPrices(date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculateFinances operation in FinanceCalculationControllerApi.
 * @export
 * @interface FinanceCalculationControllerApiCalculateFinancesRequest
 */
export interface FinanceCalculationControllerApiCalculateFinancesRequest {
    /**
     * 
     * @type {number}
     * @memberof FinanceCalculationControllerApiCalculateFinances
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof FinanceCalculationControllerApiCalculateFinances
     */
    readonly date: string
}

/**
 * Request parameters for getEnergyPrices operation in FinanceCalculationControllerApi.
 * @export
 * @interface FinanceCalculationControllerApiGetEnergyPricesRequest
 */
export interface FinanceCalculationControllerApiGetEnergyPricesRequest {
    /**
     * 
     * @type {string}
     * @memberof FinanceCalculationControllerApiGetEnergyPrices
     */
    readonly date: string
}

/**
 * FinanceCalculationControllerApi - object-oriented interface
 * @export
 * @class FinanceCalculationControllerApi
 * @extends {BaseAPI}
 */
export class FinanceCalculationControllerApi extends BaseAPI {
    /**
     * 
     * @param {FinanceCalculationControllerApiCalculateFinancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FinanceCalculationControllerApi
     */
    public calculateFinances(requestParameters: FinanceCalculationControllerApiCalculateFinancesRequest, options?: AxiosRequestConfig) {
        return FinanceCalculationControllerApiFp(this.configuration).calculateFinances(requestParameters.bpsId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinanceCalculationControllerApiGetEnergyPricesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceCalculationControllerApi
     */
    public getEnergyPrices(requestParameters: FinanceCalculationControllerApiGetEnergyPricesRequest, options?: AxiosRequestConfig) {
        return FinanceCalculationControllerApiFp(this.configuration).getEnergyPrices(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}
