import React from 'react'

import {createTheme, ThemeProvider as MUIThemeProvider} from '@mui/material'
import {skSK as datepicker_skSK} from '../translations/skSK-mui-datepicker-locale'
import {skSK} from '@mui/material/locale'

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#92C937',
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: '#7B838A',
      },
    },
    components: {
      MuiDialog: {
        defaultProps: {
          maxWidth: 'xs',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
    },
  },
  skSK,
  datepicker_skSK,
)

type ThemeProviderProps = Omit<React.ComponentProps<typeof MUIThemeProvider>, 'theme'>

export const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  return <MUIThemeProvider theme={theme} {...props} />
}
