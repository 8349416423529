import {Configuration} from './generated'
import {environment} from '../environment'

export const getConfiguration = () => {
  const accessToken = sessionStorage.getItem('accessToken')
  return new Configuration({
    basePath: environment.BACKEND_BASE_PATH,
    baseOptions: {headers: {Authorization: `Bearer ${accessToken}`}},
  })
}

export const getLoginConfiguration = () => {
  return new Configuration({
    basePath: environment.BACKEND_BASE_PATH,
  })
}
