import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'
import {ConfirmCreateProfileDialog} from './ConfirmCreateProfileDialog'
import {useAsyncMethod} from '../hooks/useAsyncMethod'
import {getNominationsV2} from '../api/nominationsApi'
import {NominationExtended} from '../api/generated'
import {useBucketStore} from '../utils/BucketStoreContext'

type CreateProfileDialogProps = {
  open: boolean
  onClose: () => void
}

export const CreateProfileDialog: React.FC<CreateProfileDialogProps> = ({open, onClose}) => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedDate} = useBucketStore('selectedDate')

  const [profileName, setProfileName] = useState<string>('')
  const [date, setDate] = useState<string | null>(selectedDate)
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true)
  const [isConfirmCreateProfileDialogOpen, setIsConfirmCreateProfileDialogOpen] = useState<boolean>(false)
  const [nominations, setNominations] = useState<NominationExtended[]>([])

  const {run: runGetNominationsV2} = useAsyncMethod(getNominationsV2)

  const handleCloseCreateProfileDialog = () => {
    setIsConfirmCreateProfileDialogOpen(false)
    onClose()
  }

  const handleConfirm = async () => {
    if (selectedBpsId) {
      const normalizedDate = date ? date.substring(0, 10) : ''
      const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

      setNominations(result?.nominations ?? [])
    }

    setIsConfirmCreateProfileDialogOpen(true)
  }

  const validateProfileName = (profileNameToValidate: string) => {
    return !profileNameToValidate.trim()
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Vytvoriť profil</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="baseline" spacing={2} justifyContent="flex-end">
            <Typography>Názov:</Typography>
            <TextField
              size={'small'}
              onChange={(e) => {
                setIsConfirmDisabled(!e.target.value?.trim())
                setProfileName(e.target.value)
              }}
              error={validateProfileName(profileName)}
              helperText={validateProfileName(profileName) ? 'Názov je prázdny' : ''}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" marginY={2}>
            <Typography>Z nominácií dňa:</Typography>
            <MobileDatePicker<string, Dayjs>
              value={date}
              onChange={(newValue) => {
                setDate(newValue?.toISOString() ?? null)
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              componentsProps={{actionBar: {actions: ['cancel', 'accept']}}}
              showToolbar={false}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={handleConfirm} variant="contained" disabled={isConfirmDisabled}>
            Pokračovať
          </Button>
        </DialogActions>
      </Dialog>

      {isConfirmCreateProfileDialogOpen && (
        <ConfirmCreateProfileDialog
          open={isConfirmCreateProfileDialogOpen}
          onClose={handleCloseCreateProfileDialog}
          nominations={nominations}
          date={date}
          profileName={profileName}
        />
      )}
    </>
  )
}
