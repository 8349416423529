import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import {I18nextProvider} from 'react-i18next'
import {NavigationRouter} from './navigation/NavigationRouter'
import {ThemeProvider} from './theme/ThemeProvider'

import i18n from './translations/i18n'
import {BucketStoreProvider} from './utils/BucketStoreContext'

import 'dayjs/locale/sk'

export const App: React.FC = () => {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sk'}>
            <BucketStoreProvider>
              <NavigationRouter />
            </BucketStoreProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </I18nextProvider>
    </>
  )
}
