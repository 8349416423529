import React, {useEffect} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {transformToKwNumber, transformToMwNumber} from '../utils/common'
import {NominationOverride, UpdateNominationOverrideRequest} from '../api/generated'
import {getAllNominationOverride, updateAllNominationOverride} from '../api/nominationsApi'
import {useBucketStore} from '../utils/BucketStoreContext'
import {BPS_CODES_TO_IDS} from '../utils/constants'
import {ConfirmResetSlcDialog} from './ConfirmResetSlcDialog'
import {UnsuccessfulResetSlcDialog} from './UnsuccessfulResetSlcDialog'
import {getFormattedTextOfLastSlcReset} from '../utils/format'
import {resetSlcControlPowerRange} from '../api/bpsApi'

type NominationOverrideDialogProps = {
  open: boolean
  onClose: () => void
}

const StyledTableHeadRowForCounterImbalanceTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

export const NominationOverrideDialog: React.FC<NominationOverrideDialogProps> = ({open, onClose}) => {
  const {data: userInfo} = useBucketStore('userInfo')

  const [nominationsOverride, setNominationsOverride] = React.useState<NominationOverride[]>([])
  const [originalNominationsOverride, setOriginalNominationsOverride] = React.useState<NominationOverride[]>([])
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)
  const [isConfirmResetSlcDialogOpen, setIsConfirmResetSlcDialogOpen] = React.useState<boolean>(false)
  const [confirmResetDisabled, setConfirmResetDisabled] = React.useState<boolean>(false)
  const [isUnsuccessfulResetSlcDialogOpen, setIsUnsuccessfulResetSlcDialogOpen] = React.useState<boolean>(false)
  const [valuesToParse, setValuesToParse] = React.useState<string>('')
  const [selectedBpsIdToResetSlc, setSelectedBpsIdToResetSlc] = React.useState<number | undefined>()
  const [resetButtonTooltip, setResetButtonTooltip] = React.useState<string>('Naposledy vyžiadané: Nikdy')

  const {run: runUpdateAllNominationOverride} = useAsyncMethodWithErrorHandling(updateAllNominationOverride)
  const {run: runGetAllNominationOverride} = useAsyncMethodWithErrorHandling(getAllNominationOverride)

  const fetchAllNominationOverride = React.useCallback(async () => {
    const result = (await runGetAllNominationOverride()).data

    const formattedResult =
      result?.nominations_override
        .map((item) => {
          item.value_ab1_mw = transformToKwNumber(item.value_ab1_mw)
          return item
        })
        .map((item) => {
          item.value_rbo_mw = transformToKwNumber(item.value_rbo_mw)
          return item
        }) ?? []

    setNominationsOverride(formattedResult)
    setOriginalNominationsOverride(JSON.parse(JSON.stringify(formattedResult))) // deep copy
  }, [runGetAllNominationOverride])

  const handleConfirm = async () => {
    setIsConfirmDisabled(true)

    await runUpdateAllNominationOverride({
      updateAllNominationOverrideRequest: {
        update_all_nomination_override: nominationsOverride.map((item) => {
          const request: UpdateNominationOverrideRequest = {
            bps_id: item.bps.id ?? 0,
            override_enabled: item.override_enabled,
            value_ab1_mw: transformToMwNumber(item.value_ab1_mw),
            value_rbo_mw: transformToMwNumber(item.value_rbo_mw),
          }
          return request
        }),
      },
    })

    fetchAllNominationOverride()
    setIsConfirmDisabled(false)
  }

  const validateIfPositiveNumber = (number: string) => {
    return Number(number) >= 0
  }

  const handleConfirmResetSlc = async () => {
    setConfirmResetDisabled(true)

    try {
      if (selectedBpsIdToResetSlc) {
        const updatedNominationOverride = await resetSlcControlPowerRange({bpsId: selectedBpsIdToResetSlc})
        nominationsOverride[
          nominationsOverride.findIndex((item) => item.bps.id == updatedNominationOverride.bps.id)
        ].last_pwr_rng_reset = updatedNominationOverride.last_pwr_rng_reset // update only last_pwr_rng_reset
        setNominationsOverride([...nominationsOverride])
      }

      setSelectedBpsIdToResetSlc(undefined)
      setConfirmResetDisabled(false)
      setIsConfirmResetSlcDialogOpen(false)
    } catch (error) {
      setSelectedBpsIdToResetSlc(undefined)
      setConfirmResetDisabled(false)
      setIsConfirmResetSlcDialogOpen(false)
      setIsUnsuccessfulResetSlcDialogOpen(true)
    }
  }

  const requestResetSlcButtonText = (bpsId: number) => {
    const no = nominationsOverride.find((nomOver) => nomOver.bps.id === bpsId)
    setResetButtonTooltip(
      no && no.last_pwr_rng_reset
        ? getFormattedTextOfLastSlcReset(no.last_pwr_rng_reset)
        : 'Naposledy vyžiadané: Nikdy',
    )
  }

  const openResetSlcDialog = (bpsId: number) => {
    setSelectedBpsIdToResetSlc(bpsId)
    setIsConfirmResetSlcDialogOpen(true)
  }

  const updateNominationOverride = (nominationOverrideEdited: NominationOverride) => {
    nominationsOverride[nominationsOverride.findIndex((item) => item.bps.id == nominationOverrideEdited.bps.id)] =
      nominationOverrideEdited
    setNominationsOverride([...nominationsOverride])
  }

  const getSumOfAB1Values = () => {
    return nominationsOverride
      .filter((item) => item.override_enabled === true)
      .reduce(function (prev, current) {
        return prev + (current.value_ab1_mw ?? 0)
      }, 0)
  }

  const getSumOfRBOValues = () => {
    return nominationsOverride
      .filter((item) => item.override_enabled === true)
      .reduce(function (prev, current) {
        return prev + (current.value_rbo_mw ?? 0)
      }, 0)
  }

  const compareValueChanges = (nominationOverrideEdited: NominationOverride, property: string) => {
    const newNO =
      nominationsOverride[nominationsOverride.findIndex((item) => item.bps.id == nominationOverrideEdited.bps.id)]
    const originalNO =
      originalNominationsOverride[
        originalNominationsOverride.findIndex((item) => item.bps.id == nominationOverrideEdited.bps.id)
      ]

    const NOProp = property as keyof NominationOverride

    if (!originalNO || !originalNO) {
      return ((newNO[NOProp] as number) ?? 0) > 0 ? true : false
    }

    if (newNO[NOProp] === originalNO[NOProp]) {
      return false
    } else {
      return true
    }
  }

  const parseValues = () => {
    const separatedBps = valuesToParse.trim().split('|')

    for (const bpsInfo of separatedBps) {
      const [bpsCode, valueAB1String, valueRBOString] = bpsInfo.split(';')

      if (bpsCode && nominationsOverride.find((no) => no.bps.id === BPS_CODES_TO_IDS.get(bpsCode))) {
        if (valueAB1String !== '' && validateIfPositiveNumber(valueAB1String)) {
          nominationsOverride[
            nominationsOverride.findIndex((item) => item.bps.id === BPS_CODES_TO_IDS.get(bpsCode))
          ].value_ab1_mw = Number(valueAB1String)
        }
        if (valueRBOString !== '' && validateIfPositiveNumber(valueRBOString)) {
          nominationsOverride[
            nominationsOverride.findIndex((item) => item.bps.id === BPS_CODES_TO_IDS.get(bpsCode))
          ].value_rbo_mw = Number(valueRBOString)
        }
      }
    }
    setNominationsOverride([...nominationsOverride])
  }

  const inputValues = () => {
    parseValues()
    setValuesToParse('')
  }

  useEffect(() => {
    if (userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR')) {
      fetchAllNominationOverride()
    }
  }, [fetchAllNominationOverride, userInfo?.roles])

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'}>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{padding: '0px 0'}}>
              <TableHead>
                <StyledTableHeadRowForCounterImbalanceTable>
                  <TableCell>Prepísať nominácie</TableCell>
                  <TableCell />
                  <TableCell />
                </StyledTableHeadRowForCounterImbalanceTable>
              </TableHead>

              <TableBody>
                <>
                  <TableRow>
                    <Stack marginTop={2} marginLeft={2} direction={'row'} justifyContent={'stretch'}>
                      <TextField
                        label={'Zadanie hodnôt cez CSV'}
                        value={valuesToParse}
                        onChange={(e) => setValuesToParse(e.target.value)}
                        fullWidth
                      />
                      <IconButton onClick={inputValues} color="primary">
                        <CheckCircleIcon />
                      </IconButton>
                    </Stack>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableCell>BPS</TableCell>
                            <TableCell>{`AB1`}</TableCell>
                            <TableCell>{`RBO`}</TableCell>
                            <TableCell>Povoliť prepisovanie</TableCell>
                            <TableCell>SLC regulačný rozsah</TableCell>
                          </TableHead>
                          <TableBody>
                            {nominationsOverride
                              .sort((item1, item2) => (item1?.bps?.id ?? 0) - (item2?.bps?.id ?? 0))
                              .map((item) => (
                                <>
                                  <TableRow key={item.bps.id}>
                                    <TableCell>
                                      <Typography variant={'body2'}>{item?.bps?.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        value={item?.value_ab1_mw}
                                        size={'small'}
                                        label={`Akt: ${transformToKwNumber(item?.current_nomination)}`}
                                        onChange={(e) => {
                                          validateIfPositiveNumber(e.target.value)
                                            ? (item.value_ab1_mw = Number(e.target.value))
                                            : (item.value_ab1_mw = 0)
                                          updateNominationOverride(item)
                                        }}
                                        focused={compareValueChanges(item, 'value_ab1_mw')}
                                        color={'warning'}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        value={item?.value_rbo_mw}
                                        size={'small'}
                                        label={`Akt: ${transformToKwNumber(item?.sent_nomination)}`}
                                        onChange={(e) => {
                                          validateIfPositiveNumber(e.target.value)
                                            ? (item.value_rbo_mw = Number(e.target.value))
                                            : (item.value_rbo_mw = 0)
                                          updateNominationOverride(item)
                                        }}
                                        focused={compareValueChanges(item, 'value_rbo_mw')}
                                        color={'warning'}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={item.override_enabled}
                                        onChange={(_, checked) => {
                                          item.override_enabled = checked
                                          updateNominationOverride(item)
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Tooltip
                                        title={resetButtonTooltip}
                                        onOpen={() => requestResetSlcButtonText(item.bps.id ?? 0)}
                                      >
                                        <Button
                                          onClick={() => openResetSlcDialog(item.bps.id ?? 0)}
                                          size="small"
                                          sx={{
                                            fontSize: '0.75rem',
                                            padding: '4px 8px',
                                            minWidth: 'auto',
                                          }}
                                        >
                                          Vyžiadať reset
                                        </Button>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            <TableRow>
                              <TableCell>
                                <Typography style={{fontWeight: 'bold'}}>Kontrolný súčet</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography style={{fontWeight: 'bold'}}>{getSumOfAB1Values()}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography style={{fontWeight: 'bold'}}>{getSumOfRBOValues()}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zavrieť
          </Button>
          <Button onClick={handleConfirm} disabled={isConfirmDisabled} variant="contained">
            Použiť
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmResetSlcDialog
        open={isConfirmResetSlcDialogOpen}
        onClose={() => setIsConfirmResetSlcDialogOpen(false)}
        onConfirm={handleConfirmResetSlc}
        confirmResetDisabled={confirmResetDisabled}
      />

      <UnsuccessfulResetSlcDialog
        open={isUnsuccessfulResetSlcDialogOpen}
        onClose={() => setIsUnsuccessfulResetSlcDialogOpen(false)}
      />
    </>
  )
}
