import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'

type SendPlanToAggregatorErrorDialogProps = {
  open: boolean
  onClose: () => void
  statusMessage: string
}

const StatusDialogTitle = (error: boolean) =>
  error ? 'Chyba odosielania plánu Agregátorovi' : 'Odoslanie plánu Agregátorovi'

const StatusDialogMessage = (statusMessage: string) =>
  statusMessage !== '' ? statusMessage : 'Odoslanie plánu Agregátorovi prebehlo úspešne'

export const SendPlanToAggregatorStatusDialog: React.FC<SendPlanToAggregatorErrorDialogProps> = ({
  open,
  onClose,
  statusMessage,
}) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>{StatusDialogTitle(statusMessage !== '')}</DialogTitle>
        <DialogContent>
          <Typography>{StatusDialogMessage(statusMessage)}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
