import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'

type UnsuccessfulResetSlcDialogProps = {
  open: boolean
  onClose: () => void
}

export const UnsuccessfulResetSlcDialog: React.FC<UnsuccessfulResetSlcDialogProps> = ({open, onClose}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Neúspešný reset SLC regulačných rozsahov</DialogTitle>
      <DialogContent>
        <Typography>Reset SLC regulačných rozsahov bol neúspešný.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained">
          Zavrieť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
