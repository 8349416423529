import {UserInfo} from '../utils/BucketStoreContext'

export const isBpsEditable = (userInfo: UserInfo | null, selectedBpsID: number | undefined) => {
  if (userInfo == null || selectedBpsID == undefined) {
    return false
  }
  if (userInfo.roles.includes('GROUP_MNG') || userInfo.roles.includes('GROUP_OPERATOR')) {
    return true
  }
  if (userInfo.roles.includes('BPS_MNG') || userInfo.roles.includes('BPS_OPERATOR')) {
    return userInfo.bps.some((bps) => bps.id == selectedBpsID)
  }
  return false
}

export const isViewOfPeriodDetailAllowed = (userInfo: UserInfo | null) => {
  if (userInfo == null) {
    return false
  }
  if (
    userInfo.roles.includes('GROUP_MNG') ||
    userInfo.roles.includes('GROUP_OPERATOR') ||
    userInfo.roles.includes('BPS_MNG')
  ) {
    return true
  } else {
    return false
  }
}

export const isUserGroupManagerOrGroupOperator = (userInfo: UserInfo | null) => {
  if (userInfo == null) {
    return false
  }
  if (userInfo.roles.includes('GROUP_MNG') || userInfo.roles.includes('GROUP_OPERATOR')) {
    return true
  }
  return false
}
