import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box} from '@mui/material'
import {formatTimeRange} from '../utils/format'
import {BpsParameters, NominationExtended, NominationDevice} from '../api/generated'
import {useBucketStore} from '../utils/BucketStoreContext'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {createProfileV2} from '../api/profilesApi'
import dayjs from 'dayjs'
import {mergeExtended} from './nominations-merge-utils'
import {getBpsDetailsV2} from '../api/bpsApi'
import {transformToKwWithEndKwWithoutDecimals} from '../utils/common'

type ConfirmCreateProfileDialogProps = {
  open: boolean
  onClose: () => void
  nominations: NominationExtended[]
  date: string | null
  profileName: string | undefined
}

export const ConfirmCreateProfileDialog: React.FC<ConfirmCreateProfileDialogProps> = ({
  open,
  onClose,
  nominations,
  date,
  profileName,
}) => {
  const [bpsParams, setBpsParams] = useState<BpsParameters | undefined>(undefined)
  const [isConfirmButtonPressed, setIsConfirmButtonPressed] = useState<boolean>(false)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {setData: setSelectedProfile} = useBucketStore('selectedProfile')
  const {data: profiles, setData: setProfiles} = useBucketStore('profiles')

  const {run: runCreateProfileV2} = useAsyncMethodWithErrorHandling(createProfileV2)
  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)

  const mergedRecords = mergeExtended(nominations)

  const handleConfirm = async () => {
    setIsConfirmButtonPressed(true)

    if (selectedBpsId && date && profileName) {
      const result = (
        await runCreateProfileV2({
          createProfileRequest: {
            bps_id: selectedBpsId,
            name: profileName,
            date_nomination: dayjs(date).format('YYYY-MM-DD'),
          },
        })
      ).data

      if (result) {
        setSelectedProfile(result)
        const newProfiles = [...profiles, result]
        setProfiles(newProfiles)
      }
    }

    onClose()
  }

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data

      setBpsParams(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
  }, [fetchBpsDetails])

  const bpsNomination = (valuePercentage: number) => {
    const firstPart = (bpsParams?.p_inst_mw ?? 0) * (valuePercentage / 100)
    return firstPart - (bpsParams?.tvs_mw ?? 0)
  }

  const PRegInterval = (devices: Array<NominationDevice> | undefined, bpsNominationValue: number) => {
    const devicesInNomination = devices?.filter((nomination) => nomination.in_nomination)

    let pRegMinMw = devicesInNomination?.reduce(function (prev, current) {
      return prev + current?.p_reg_min_mw
    }, 0)
    const pRegMaxMw = devicesInNomination?.reduce(function (prev, current) {
      return prev + current?.p_reg_max_mw
    }, 0)

    if (!pRegMinMw || pRegMinMw > bpsNominationValue) {
      pRegMinMw = bpsNominationValue
    }

    return `, (${transformToKwWithEndKwWithoutDecimals(pRegMinMw)} - ${transformToKwWithEndKwWithoutDecimals(
      pRegMaxMw,
    )})`
  }

  const BoxPart = (percentage: number | undefined, devices: Array<NominationDevice> | undefined) => {
    const bpsNominationValue = bpsNomination(percentage ?? 0)

    return (
      <>
        {` `}
        {transformToKwWithEndKwWithoutDecimals(bpsNominationValue)}
        {PRegInterval(devices, bpsNominationValue)}
      </>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Vytvoriť profil</DialogTitle>
      <DialogContent>
        {nominations.length > 0 && (
          <Box>
            <Typography fontWeight={500}>Profil nominácií</Typography>
            {mergedRecords.map((nomination) => (
              <Box key={nomination.id} marginTop="2px">
                <Typography>
                  {formatTimeRange(nomination.time_from, nomination.time_to)}, {nomination.value_percentage}%,
                  {BoxPart(nomination.value_percentage, nomination.nomination_devices)}
                </Typography>
                {nomination.note && <Typography variant="body2">Poznámka: {nomination.note}</Typography>}
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={handleConfirm} variant="contained" disabled={isConfirmButtonPressed}>
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
