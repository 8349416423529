import {getConfiguration} from './configuration'
import {
  NominationSnapshotControllerApi,
  NominationSnapshotControllerApiGetNominationSnapshotsRequest,
  NominationSnapshotControllerApiGetGroupNominationSnapshotsRequest,
} from './generated'

export const getNominationSnapshots = async (params: NominationSnapshotControllerApiGetNominationSnapshotsRequest) => {
  const configuration = getConfiguration()
  const api = new NominationSnapshotControllerApi(configuration)

  return (await api.getNominationSnapshots(params)).data
}

export const getGroupNominationSnapshots = async (
  params: NominationSnapshotControllerApiGetGroupNominationSnapshotsRequest,
) => {
  const configuration = getConfiguration()
  const api = new NominationSnapshotControllerApi(configuration)

  return (await api.getGroupNominationSnapshots(params)).data
}
