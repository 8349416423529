import {
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Create} from '@mui/icons-material'
import React, {useState} from 'react'
import {StyledIconButton} from '.././GroupNominationsTable'
import {useBucketStore} from '../../../utils/BucketStoreContext'

const StyledTableHeadRowTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const StyledTableCell = styled(TableCell)(() => ({
  width: '70%',
}))

type BpsRemoteControlTableProps = {
  setIsBpsEditRemoteControlDialogOpen: (flag: boolean) => void
  checkBoxThreshold: boolean
  checkBoxRemoteControl: boolean
  forceKeepBspRcOffer: boolean
  forceLimitedResponsivity: boolean
  controlPowerRangeModeType: string
}

export const BpsRemoteControlTable: React.FC<BpsRemoteControlTableProps> = ({
  setIsBpsEditRemoteControlDialogOpen,
  checkBoxThreshold,
  checkBoxRemoteControl,
  forceKeepBspRcOffer,
  forceLimitedResponsivity,
  controlPowerRangeModeType,
}) => {
  const {data: userInfo} = useBucketStore('userInfo')
  const [openTableRow, setOpenTableRow] = useState<number | undefined>(1)

  const handleOpenBpsRemoteControl = (index: number) => {
    setOpenTableRow(index)
  }

  const handleCloseBpsRemoteControlTable = () => {
    setOpenTableRow(-1)
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{gap: '12px', display: 'flex'}}
        style={{marginTop: '30px'}}
      >
        <TableContainer component={Paper}>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableHead>
              <StyledTableHeadRowTable>
                <StyledTableCell>Riadenie na diaľku</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      openTableRow == 1 ? handleCloseBpsRemoteControlTable() : handleOpenBpsRemoteControl(1)
                    }
                  >
                    {openTableRow == 1 ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </StyledTableCell>
              </StyledTableHeadRowTable>
            </TableHead>

            <TableBody>
              <>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={openTableRow == 1} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table size="small" sx={{padding: '20px 0'}}>
                          <TableHead>
                            <StyledTableCell>Parametre</StyledTableCell>
                            <StyledTableCell>Hodnota</StyledTableCell>
                            <StyledTableCell style={{borderBottom: 'none'}}>
                              <StyledIconButton
                                sx={{marginLeft: '5px'}}
                                color="primary"
                                onClick={() => setIsBpsEditRemoteControlDialogOpen(true)}
                                disabled={!userInfo?.roles.includes('GROUP_MNG')}
                              >
                                <Create fontSize="small" />
                              </StyledIconButton>
                            </StyledTableCell>
                          </TableHead>
                          <TableBody>
                            <>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>Riadená na prahu</StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  <Checkbox style={{padding: 0}} checked={checkBoxThreshold} disabled />
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  Povoliť vzdialené ovládanie
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  <Checkbox style={{padding: 0}} checked={checkBoxRemoteControl} disabled />
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  Držať ponuku DRV agregátorom
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  <Checkbox style={{padding: 0}} checked={forceKeepBspRcOffer} disabled />
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  Obmedzený regulačný rozsah
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  <Checkbox style={{padding: 0}} checked={forceLimitedResponsivity} disabled />
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  Mód určenia regulačného rozhsahu
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  {controlPowerRangeModeType}
                                </StyledTableCell>
                              </TableRow>
                            </>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  )
}
