import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import {useBucketStore} from '../utils/BucketStoreContext'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {updateRemoteControlForBps} from '../api/bpsApi'
import {BpsRemoteControl, BpsRemoteControlCtrlPwrRngModeEnum} from '../api/generated'
import {CONTROL_POWER_RANGE_MODE_TYPES} from '../utils/constants'

type EditBpsRemoteControlDialogProps = {
  open: boolean
  onClose: () => void
  onUpdate: () => void
  bpsRemoteControl: BpsRemoteControl | undefined
}

export const EditBpsRemoteControlDialog: React.FC<EditBpsRemoteControlDialogProps> = ({
  open,
  onClose,
  onUpdate,
  bpsRemoteControl,
}) => {
  const [checkBoxThreshold, setCheckBoxThreshold] = React.useState<boolean>(
    bpsRemoteControl?.control_at_threshold === undefined ? false : bpsRemoteControl.control_at_threshold,
  )
  const [checkBoxRemoteControl, setCheckBoxRemoteControl] = React.useState<boolean>(
    bpsRemoteControl?.rc_enabled === undefined ? false : bpsRemoteControl.rc_enabled,
  )
  const [forceKeepBspRcOffer, setForceKeepBspRcOffer] = React.useState<boolean>(
    bpsRemoteControl?.force_keep_bsp_rc_offer === undefined ? false : bpsRemoteControl.force_keep_bsp_rc_offer,
  )
  const [forceLimitedResponsivity, setForceLimitedResponsivity] = React.useState<boolean>(
    bpsRemoteControl?.force_limited_responsivity === undefined ? false : bpsRemoteControl.force_limited_responsivity,
  )
  const [controlPowerRangeModeType, setControlPowerRangeModeType] = React.useState(
    bpsRemoteControl?.ctrl_pwr_rng_mode === undefined
      ? CONTROL_POWER_RANGE_MODE_TYPES[1]
      : CONTROL_POWER_RANGE_MODE_TYPES[
          Object.values(BpsRemoteControlCtrlPwrRngModeEnum).indexOf(bpsRemoteControl.ctrl_pwr_rng_mode)
        ],
  )
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const {run: runUpdateRemoteControlForBps} = useAsyncMethodWithErrorHandling(updateRemoteControlForBps)

  const handleConfirm = async () => {
    setIsConfirmDisabled(true)
    if (selectedBpsId) {
      await runUpdateRemoteControlForBps({
        updateBpsRemoteControlRequest: {
          bps_id: selectedBpsId,
          control_at_threshold: checkBoxThreshold,
          rc_enabled: checkBoxRemoteControl,
          force_keep_bsp_rc_offer: forceKeepBspRcOffer,
          force_limited_responsivity: forceLimitedResponsivity,
          ctrl_pwr_rng_mode: Object.values(BpsRemoteControlCtrlPwrRngModeEnum)[
            CONTROL_POWER_RANGE_MODE_TYPES.indexOf(controlPowerRangeModeType)
          ],
        },
      })
    }
    setIsConfirmDisabled(false)
    onClose()
    onUpdate()
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Upraviť riadenie na diaľku</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography>Riadená na prahu</Typography>
            <Checkbox
              checked={checkBoxThreshold}
              onChange={(_, checked) => {
                setCheckBoxThreshold(checked)
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography>Povoliť vzdialené ovládanie</Typography>
            <Checkbox
              checked={checkBoxRemoteControl}
              onChange={(_, checked) => {
                setCheckBoxRemoteControl(checked)
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography>Držať ponuku DRV agregátorom</Typography>
            <Checkbox
              checked={forceKeepBspRcOffer}
              onChange={(_, checked) => {
                setForceKeepBspRcOffer(checked)
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography>Obmedzený regulačný rozsah</Typography>
            <Checkbox
              checked={forceLimitedResponsivity}
              onChange={(_, checked) => {
                setForceLimitedResponsivity(checked)
              }}
            />
          </Stack>
          <Stack sx={{marginTop: '10px'}}>
            <Typography>Mód určenia regulačného rozhsahu</Typography>
            <FormControl fullWidth sx={{marginBottom: '5px'}} size="small">
              <Select
                value={controlPowerRangeModeType}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green',
                  },
                }}
                onChange={(event) => {
                  if (event.target.value) {
                    setControlPowerRangeModeType(event.target.value)
                  }
                }}
              >
                {Object.values(CONTROL_POWER_RANGE_MODE_TYPES).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={handleConfirm} variant="contained" disabled={isConfirmDisabled}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
