/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LoginRequest } from '../model';
// @ts-ignore
import { RefreshTokenRequest } from '../model';
/**
 * JwtAuthControllerApi - axios parameter creator
 * @export
 */
export const JwtAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('authenticateUser', 'loginRequest', loginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenRequest: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('refreshToken', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/auth/refreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JwtAuthControllerApi - functional programming interface
 * @export
 */
export const JwtAuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JwtAuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUser(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JwtAuthControllerApi - factory interface
 * @export
 */
export const JwtAuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JwtAuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(loginRequest: LoginRequest, options?: any): AxiosPromise<object> {
            return localVarFp.authenticateUser(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<object> {
            return localVarFp.refreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authenticateUser operation in JwtAuthControllerApi.
 * @export
 * @interface JwtAuthControllerApiAuthenticateUserRequest
 */
export interface JwtAuthControllerApiAuthenticateUserRequest {
    /**
     * 
     * @type {LoginRequest}
     * @memberof JwtAuthControllerApiAuthenticateUser
     */
    readonly loginRequest: LoginRequest
}

/**
 * Request parameters for refreshToken operation in JwtAuthControllerApi.
 * @export
 * @interface JwtAuthControllerApiRefreshTokenRequest
 */
export interface JwtAuthControllerApiRefreshTokenRequest {
    /**
     * 
     * @type {RefreshTokenRequest}
     * @memberof JwtAuthControllerApiRefreshToken
     */
    readonly refreshTokenRequest: RefreshTokenRequest
}

/**
 * JwtAuthControllerApi - object-oriented interface
 * @export
 * @class JwtAuthControllerApi
 * @extends {BaseAPI}
 */
export class JwtAuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {JwtAuthControllerApiAuthenticateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtAuthControllerApi
     */
    public authenticateUser(requestParameters: JwtAuthControllerApiAuthenticateUserRequest, options?: AxiosRequestConfig) {
        return JwtAuthControllerApiFp(this.configuration).authenticateUser(requestParameters.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JwtAuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtAuthControllerApi
     */
    public refreshToken(requestParameters: JwtAuthControllerApiRefreshTokenRequest, options?: AxiosRequestConfig) {
        return JwtAuthControllerApiFp(this.configuration).refreshToken(requestParameters.refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
