import {Checkbox, FormControlLabel, Stack} from '@mui/material'
import React from 'react'
import {NominationInterval} from '../components/AddNominationDialog'

type NominationDialogIntervalSelectorProps = {
  selectedInterval: NominationInterval
  handleFifteenMinutesIntervalSelected: () => void
  handleHoursIntervalSelected: () => void
  handleAllDaySelected: () => void
}

export const NominationDialogIntervalSelector: React.FC<NominationDialogIntervalSelectorProps> = ({
  selectedInterval,
  handleFifteenMinutesIntervalSelected,
  handleHoursIntervalSelected,
  handleAllDaySelected,
}) => {
  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-evenly">
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedInterval === NominationInterval.FifteenMinutes}
            onChange={handleFifteenMinutesIntervalSelected}
          />
        }
        label="15-min."
        labelPlacement="end"
      />
      <FormControlLabel
        control={
          <Checkbox checked={selectedInterval === NominationInterval.Hours} onChange={handleHoursIntervalSelected} />
        }
        label="Hodiny"
        labelPlacement="end"
      />
      <FormControlLabel
        control={<Checkbox checked={selectedInterval === NominationInterval.AllDay} onChange={handleAllDaySelected} />}
        label="Celý deň"
        labelPlacement="end"
      />
    </Stack>
  )
}
