import {PickersLocaleText} from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'

// maps ClockPickerView to its translation
const timeViews = {
  hours: 'Hodiny',
  minutes: 'Minúty',
  seconds: 'Sekundy',
}

// maps PickersToolbar["viewType"] to its translation
const pickerViews = {
  calendar: 'kalendára',
  clock: 'času',
}

const skSKPickers: PickersLocaleText<Dayjs> = {
  // Calendar navigation
  previousMonth: 'Predchádzajúci mesiac',
  nextMonth: 'Nasledujúci mesiac',

  // View navigation
  openPreviousView: 'otvoriť predchádzajúce zobrazenie',
  openNextView: 'otvoriť ďalšie zobrazenie',
  calendarViewSwitchingButtonAriaLabel: (view) =>
    view === 'year'
      ? 'ročné zobrazenie otvorené, prepnite do zobrazenia kalendára'
      : 'zobrazenie kalendára otvorené, prepnite do zobrazenia roku',
  inputModeToggleButtonAriaLabel: (isKeyboardInputOpen: boolean, viewType: 'calendar' | 'clock') =>
    isKeyboardInputOpen
      ? `Zobrazenie pre zadávanie textu je otvorené, prepnite do zobrazenia ${pickerViews[viewType]}`
      : `Zobrazenie ${pickerViews[viewType]} je otvorené, prepnite do zobrazenia textového poľa`,

  // DateRange placeholders
  start: 'Začiatok',
  end: 'Koniec',

  // Action bar
  cancelButtonLabel: 'Zrušiť',
  clearButtonLabel: 'Vymazať',
  okButtonLabel: 'Potvrdiť',
  todayButtonLabel: 'Dnes',

  // Toolbar titles
  datePickerDefaultToolbarTitle: 'Vyberte dátum',
  dateTimePickerDefaultToolbarTitle: 'Vyberte dátum a čas',
  timePickerDefaultToolbarTitle: 'Vyberte čas',
  dateRangePickerDefaultToolbarTitle: 'Vyberete rozmedzie dátumov',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `${timeViews[view] ?? view} vybrané. ${
      time === null ? 'Nie je vybraný čas' : `Vybraný čas je ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} hodín`,
  minutesClockNumberText: (minutes) => `${minutes} minút`,
  secondsClockNumberText: (seconds) => `${seconds} sekúnd`,

  // Open picker labels
  openDatePickerDialogue: (value, utils) =>
    value !== null && utils.isValid(value)
      ? `Vybraný dátum, vybraný dátum je ${utils.format(value, 'fullDate')}`
      : 'Vyberte dátum',
  openTimePickerDialogue: (value, utils) =>
    value !== null && utils.isValid(value)
      ? `Vybraný čas, vybraný čas je ${utils.format(value, 'fullTime')}`
      : 'Vyberte čas',

  // Table labels
  timeTableLabel: 'vyberte čas',
  dateTableLabel: 'vyberte dátum',
}

export const skSK = {
  components: {
    MuiLocalizationProvider: {
      defaultProps: {
        localeText: {...skSKPickers},
      },
    },
  },
}
