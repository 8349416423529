import {Box, Button, Link, Stack, Tab, Tabs} from '@mui/material'
import React, {useState} from 'react'

import {useBucketStoreContext} from '../../utils/BucketStoreContext'
import {ProductionBpsView} from './ProductionBpsView'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {useNavigate} from 'react-router'
import {absolutePath} from '../../navigation/utils'

export const Dispatch: React.FC = () => {
  const [selectedTab, setselectedTab] = useState<'production-bps-view' | 'warnings-view' | 'current-supply-view'>(
    'production-bps-view',
  )

  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const logout = () => {
    clearAllBuckets()
    sessionStorage.clear()
    navigation(absolutePath('login'))
  }

  const goToDashboard = () => {
    navigation(absolutePath('dashboard'))
  }

  dayjs.extend(utc)
  dayjs.extend(timezone)

  return (
    <>
      <Stack sx={{position: 'relative'}}>
        <Box sx={{position: 'absolute', right: 0, top: '-30px'}}>
          <Button variant="text" sx={{right: 15}} onClick={() => goToDashboard()}>
            Nominácie
          </Button>

          <Link component="button" variant="subtitle2" onClick={() => logout()}>
            Odhlásiť sa
          </Link>
        </Box>

        <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: '8px'}}>
          <Tabs variant="scrollable" value={selectedTab} onChange={(_, newValue) => setselectedTab(newValue)}>
            <Tab label="Report" value="production-bps-view" />
          </Tabs>
        </Box>

        {selectedTab === 'production-bps-view' && <ProductionBpsView />}
      </Stack>
    </>
  )
}
