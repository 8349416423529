import {
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {isBpsEditable, isViewOfPeriodDetailAllowed} from '../../../utils/validateUserRolesAndBps'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Create} from '@mui/icons-material'
import React, {useState} from 'react'
import {StyledIconButton} from '../GroupNominationsTable'
import {useBucketStore} from '../../../utils/BucketStoreContext'
import {transformToKwWithEndKw} from '../../../utils/common'
import {Device} from '../../../api/generated'

const StyledTableRowForDeviceTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[200],
}))

const StyledTableHeadRowForDeviceTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const TableHeadComponent = () => (
  <TableHead>
    <StyledTableHeadRowForDeviceTable>
      <TableCell>Názov zariadenia</TableCell>
      <TableCell>Inštalovaný výkon [kW]</TableCell>
      <TableCell />
    </StyledTableHeadRowForDeviceTable>
  </TableHead>
)

type DeviceTableProps = {
  devices: Device[]
  onEdit: (index: number) => void
}

export const DeviceTable: React.FC<DeviceTableProps> = ({devices, onEdit}) => {
  const [openTableRow, setOpenTableRow] = useState<number | undefined>(-1)
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const handleOpenDeviceParams = (index: number) => {
    setOpenTableRow(index)
  }

  const handleCloseDeviceParams = () => {
    setOpenTableRow(-1)
  }

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{gap: '12px', display: 'flex'}}>
      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHeadComponent />

          <TableBody>
            {devices.map((item, index) => (
              <>
                <StyledTableRowForDeviceTable key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{transformToKwWithEndKw(item.p_inst_mw)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        openTableRow == index ? handleCloseDeviceParams() : handleOpenDeviceParams(index)
                      }
                      disabled={!isViewOfPeriodDetailAllowed(userInfo)}
                    >
                      {openTableRow == index ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                </StyledTableRowForDeviceTable>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={openTableRow == index} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table size="small" sx={{padding: '20px 0'}}>
                          <TableHead>
                            <TableCell>Parameter</TableCell>
                            <TableCell>Hodnota</TableCell>
                            <TableCell />
                            <TableCell>
                              <StyledIconButton
                                sx={{marginLeft: '5px'}}
                                color="primary"
                                disabled={!isBpsEditable(userInfo, selectedBpsId)}
                                onClick={() => onEdit(item.id)}
                              >
                                <Create fontSize="small" />
                              </StyledIconButton>
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            <>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>Min. výkon dosiahnuteľný</TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  {transformToKwWithEndKw(item.p_dos_min_mw)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>Max. výkon dosiahnuteľný</TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  {transformToKwWithEndKw(item.p_dos_max_mw)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>Min. výkon regulačný</TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  {transformToKwWithEndKw(item.p_reg_min_mw)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>Max. výkon regulačný</TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  {transformToKwWithEndKw(item.p_reg_max_mw)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>V prevádzke</TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  <Checkbox checked={item.in_service} disabled={true} />
                                </TableCell>
                              </TableRow>
                            </>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
