/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'bps_id': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'p_inst_mw': number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'p_dos_min_mw': number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'p_dos_max_mw': number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'p_reg_min_mw': number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'p_reg_max_mw': number;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'in_service': boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'role'?: DeviceRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'type'?: DeviceTypeEnum;
}

export const DeviceRoleEnum = {
    Generator: 'GENERATOR',
    Consumption: 'CONSUMPTION',
    Storage: 'STORAGE',
    Other: 'OTHER'
} as const;

export type DeviceRoleEnum = typeof DeviceRoleEnum[keyof typeof DeviceRoleEnum];
export const DeviceTypeEnum = {
    Chp: 'CHP',
    Pve: 'PVE',
    Bess: 'BESS'
} as const;

export type DeviceTypeEnum = typeof DeviceTypeEnum[keyof typeof DeviceTypeEnum];


