import {Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button} from '@mui/material'
import React from 'react'
import {deleteProfileV2} from '../api/profilesApi'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../utils/BucketStoreContext'

type DeleteProfileDialogProps = {
  open: boolean
  onClose: () => void
}

export const DeleteProfileDialog: React.FC<DeleteProfileDialogProps> = ({open, onClose}) => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedProfile, setData: setSelectedProfile} = useBucketStore('selectedProfile')
  const {data: profiles, setData: setProfiles} = useBucketStore('profiles')

  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)

  const {run: runDeleteProfileV2} = useAsyncMethodWithErrorHandling(deleteProfileV2)

  const handleConfirm = async () => {
    setIsConfirmDisabled(true)
    if (selectedBpsId && selectedProfile && selectedProfile.id) {
      const result = (await runDeleteProfileV2({bpsId: selectedBpsId, id: selectedProfile.id})).data
      if (result) {
        const profilesAfterDelete = profiles.filter((profile) => profile.id != result.id)
        setProfiles(profilesAfterDelete)
        setSelectedProfile(profilesAfterDelete[0])
      }
    }

    setIsConfirmDisabled(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Vymazať profil</DialogTitle>
      <DialogContent>
        <Typography>
          Želáte si vymazať&nbsp;
          <strong>{selectedProfile?.name}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="error" disabled={isConfirmDisabled}>
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
