import React, {createContext, Dispatch, SetStateAction, useCallback, useContext, useState} from 'react'
import {accessTokenToUserInfo} from '../utils/parseJwt'
import {BpsCalculatedNomination, NominationProfile, ProductionStatusResponse, Profile} from '../api/generated'
import {NominationExtendedFull} from '../types/nominationExtended'
import {NominationProfileWithDevices} from '../api/generated/model/nomination-profile-with-devices'

export type Bps = {id: number; name: string}
export type UserInfo = {
  bps: Bps[]
  roles: string[]
  userId: number
  sessionStartedAt: number
  sessionExpiration: number
}
export type BucketStore = {
  nominationsExtended: NominationExtendedFull[]
  groupNominations: BpsCalculatedNomination[]
  userInfo: UserInfo | null
  selectedDate: string
  selectedBpsId: number | undefined
  profiles: Profile[]
  selectedProfile: Profile | undefined
  profileNominations: NominationProfile[]
  profileNominationsWithDevices: NominationProfileWithDevices[]
  bpsNominationTableOpen: boolean
  productionStatusEnum: ProductionStatusResponse | undefined
}

export type BucketName = keyof BucketStore
export type BucketContent<K extends BucketName> = BucketStore[K]

type BucketStoreContextType = {
  cache: BucketStore
  setCache: Dispatch<SetStateAction<BucketStore>>
}

const defaultCache: BucketStore = {
  nominationsExtended: [],
  groupNominations: [],
  userInfo: accessTokenToUserInfo(sessionStorage.getItem('accessToken')),
  selectedDate: new Date().toISOString(),
  selectedBpsId: undefined,
  profiles: [],
  selectedProfile: undefined,
  profileNominations: [],
  profileNominationsWithDevices: [],
  bpsNominationTableOpen: false,
  productionStatusEnum: undefined,
}

export const BucketStoreContext = createContext<BucketStoreContextType | undefined>(undefined)

export const BucketStoreProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [cache, setCache] = useState<BucketStore>(defaultCache)

  const provider: BucketStoreContextType = {cache, setCache}
  return <BucketStoreContext.Provider value={provider}>{children}</BucketStoreContext.Provider>
}

type BucketContentSetter<K extends BucketName> = BucketContent<K> | ((prev: BucketContent<K>) => BucketContent<K>)

type BucketStoreReturn<K extends BucketName> = {
  data: BucketStore[K]
  setData: (setter: BucketContent<K> | ((prev: BucketContent<K>) => BucketContent<K>)) => void
}

export const useBucketStore = <K extends BucketName>(bucketName: K): BucketStoreReturn<K> => {
  const dataCacheContext = useContext(BucketStoreContext)
  if (!dataCacheContext) throw new Error('No BucketStoreContext.Provider found when calling useBucketStore.')

  const cacheBucket = dataCacheContext.cache[bucketName]
  const setCache = dataCacheContext.setCache

  const setBucket = useCallback(
    (setter: BucketContentSetter<K>) => {
      setCache((prev) => {
        const newBucket = typeof setter === 'function' ? setter(prev[bucketName]) : setter
        return {...prev, [bucketName]: newBucket}
      })
    },
    [bucketName, setCache],
  )

  const dataCacheReturn = {data: cacheBucket, setData: setBucket}
  return dataCacheReturn
}

export const useBucketStoreContext = () => {
  const dataCacheContext = useContext(BucketStoreContext)
  if (!dataCacheContext) throw new Error('No BucketStoreContext.Provider found when calling useBucketStore.')

  const clearAllBuckets = useCallback(() => {
    dataCacheContext.setCache(() => {
      return {
        nominationsExtended: [],
        groupNominations: [],
        userInfo: null,
        selectedDate: new Date().toISOString(),
        selectedBpsId: undefined,
        profiles: [],
        selectedProfile: undefined,
        profileNominations: [],
        profileNominationsWithDevices: [],
        bpsNominationTableOpen: false,
        productionStatusEnum: undefined,
      }
    })
  }, [dataCacheContext])

  const dataCacheReturn = {clearAllBuckets}
  return dataCacheReturn
}
