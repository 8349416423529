import React, {useState, useEffect} from 'react'
import {Bps as BpsGenerated, BpsReport} from '../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import Chart from 'chart.js/auto'
import {CategoryScale} from 'chart.js'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import dayjs from 'dayjs'
import {ReportDatePicker} from './ReportDatePicker'
import {getAllBps} from '../../api/bpsApi'
import {getBpsGroupReport} from '../../api/reportApi'
import {ProductionGraph} from './ProductionGraph'
import {ReportExportDialog} from '../ReportExportDialog'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {isUserGroupManagerOrGroupOperator} from '../../utils/validateUserRolesAndBps'

Chart.register(CategoryScale)

export const ProductionBpsView: React.FC = () => {
  const {data: userInfo} = useBucketStore('userInfo')

  const [selectedDate, setSelectedDate] = useState<string>(dayjs().subtract(1, 'day').toISOString())
  const [bpsList, setBpsList] = useState<BpsGenerated[]>([])
  const [isBpsListLoaded, setIsBpsListLoaded] = useState<boolean>(false)
  const [bpsGroupReport, setBpsGroupReport] = useState<BpsReport[] | undefined>([])
  const [bpsGroupFinanceCalculation, setBpsGroupFinanceCalculation] = useState<number>()

  const {run: runGetAllBps} = useAsyncMethodWithErrorHandling(getAllBps)
  const {run: runGetBpsGroupReport} = useAsyncMethodWithErrorHandling(getBpsGroupReport)

  const [selectedBpsCheckBoxes, setSelectedBpsCheckBoxes] = useState<number[]>([])
  const [checkAllSelected, setCheckAllSelected] = useState<boolean>(false)

  const [renderGraph, setRenderGraph] = useState<number[]>([])
  const [previousSelectedDate, setPreviousSelectedDate] = useState<string>('')

  const [isReportExportDialogOpen, setIsReportExportDialogOpen] = useState<boolean>(false)

  const fetchBpsList = React.useCallback(async () => {
    const result = (await runGetAllBps()).data
    const allBps = result?.all_bps_for_view ?? []
    setBpsList(allBps)
  }, [runGetAllBps])

  const fetchBpsGroupReport = React.useCallback(async () => {
    const date = selectedDate.substring(0, 10)

    if (dayjs(date).isAfter(dayjs().subtract(1, 'day'))) {
      // BGA-336 do not load data for today and future
      setBpsGroupReport(undefined)
      setBpsGroupFinanceCalculation(undefined)
      return
    }

    const result = (await runGetBpsGroupReport({dateFrom: date, dateTo: date})).data // dateTo is the same as dateFrom

    setBpsGroupReport(result?.group_report)
    setBpsGroupFinanceCalculation(result?.finance_calculation)
  }, [runGetBpsGroupReport, selectedDate])

  const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
    backgroundColor: theme.palette.grey[300],
  }))

  const checkBoxHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target
    const value = Number.parseInt(e.target.value)

    if (checked) {
      setSelectedBpsCheckBoxes([...selectedBpsCheckBoxes, value * 1])
    } else {
      setSelectedBpsCheckBoxes(selectedBpsCheckBoxes.filter((bpsId) => bpsId !== value * 1))
      setCheckAllSelected(false) // uncheck checkAll when unchecking individual checkboxes
    }
  }

  const checkBoxHandleChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target

    if (checked) {
      const collection = bpsList.map((bps) => bps.id).filter((id) => id !== undefined) as number[]
      setSelectedBpsCheckBoxes(collection)
    } else {
      setSelectedBpsCheckBoxes([])
    }

    setCheckAllSelected(checked)
  }

  const handleDisplayGraphs = () => {
    setRenderGraph(selectedBpsCheckBoxes)
  }

  const handleCloseReportExportDialog = () => {
    setIsReportExportDialogOpen(false)
  }

  const checkDate = (date: string) => {
    if (date) {
      return dayjs(date).format('DD.MM.YYYY') + ', ' + date.substring(11, 16)
    } else {
      return '-'
    }
  }

  const checkImbalanceValue = (value: number) => {
    if (!isNaN(value)) {
      return value
    } else {
      return '-'
    }
  }

  useEffect(() => {
    if (!isBpsListLoaded) {
      setIsBpsListLoaded(true)
      fetchBpsList()
    }

    setRenderGraph([]) // when changing date, hide graphs

    if (selectedDate !== previousSelectedDate) {
      setPreviousSelectedDate(selectedDate)
    } else {
      fetchBpsGroupReport()
    }
  }, [
    fetchBpsList,
    fetchBpsGroupReport,
    isBpsListLoaded,
    previousSelectedDate,
    selectedDate, // must stay, invoke rerender when changing date
  ])

  return (
    <>
      <Stack marginTop={1} marginBottom={2} direction="row" alignItems="center" justifyContent="flex-start">
        <Button
          onClick={() => setIsReportExportDialogOpen(true)}
          sx={{right: 15, display: isUserGroupManagerOrGroupOperator(userInfo) ? null : 'none'}}
        >
          OKTE dáta na môj email
        </Button>
        <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex'}}>
          <ReportDatePicker value={selectedDate} onChange={setSelectedDate} showToolbar={false} />
        </Container>
      </Stack>

      <Stack direction="row">
        <Stack width="35%">
          <FormGroup>
            <FormControlLabel
              key={'checkbox_all'}
              control={<Checkbox checked={checkAllSelected} onChange={checkBoxHandleChangeAll} />}
              label="Označiť všetko"
            />

            <Stack width="95%">
              <div style={{height: '2px', backgroundColor: '#E0E0E0'}} />
            </Stack>

            {bpsList.map((bps) => (
              <FormControlLabel
                key={'checkbox_' + bps.id}
                label={bps.name}
                control={
                  <Checkbox
                    value={bps.id}
                    checked={selectedBpsCheckBoxes.includes(bps.id as number)}
                    onChange={checkBoxHandleChange}
                  />
                }
              />
            ))}

            <Stack width="95%">
              <div style={{height: '2px', backgroundColor: '#E0E0E0'}} />
            </Stack>
          </FormGroup>

          <Stack marginTop={5} width="95%">
            <Typography marginTop={3} sx={{fontSize: '20px'}} component={'span'}>
              Vyčíslenie finančnej zodpovednosti pre skupinu:{' '}
              <Box fontWeight="Bold" display="inline">
                {bpsGroupFinanceCalculation}
              </Box>{' '}
              €/deň
            </Typography>
          </Stack>
        </Stack>

        <Stack width="75%">
          <TableContainer component={Paper}>
            <Table size="small" sx={{padding: '20px 0'}}>
              <TableHead>
                <StyledTableHeadRow>
                  <TableCell rowSpan={2} align="center">
                    BPS
                  </TableCell>
                  <TableCell rowSpan={2} align="center">
                    Finančná zodpovednosť (predbežná hodnota)
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    Perióda s minimálnou odchýlkou od nominácií
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    Perióda s maximálnou odchýlkou od nominácií
                  </TableCell>
                </StyledTableHeadRow>
                <StyledTableHeadRow>
                  <TableCell align="center">Nominácia (MW)</TableCell>
                  <TableCell align="center">Dodávka (MW)</TableCell>
                  <TableCell align="center">Odchýlka (MW)</TableCell>
                  <TableCell align="center">Nominácia (MW)</TableCell>
                  <TableCell align="center">Dodávka (MW)</TableCell>
                  <TableCell align="center">Odchýlka (MW)</TableCell>
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {bpsGroupReport?.map((item, i) => (
                  <React.Fragment key={i}>
                    <TableRow>
                      <TableCell rowSpan={2} align="center">
                        {item.bps_name}
                      </TableCell>
                      <TableCell rowSpan={2} align="center">
                        {item.imbalance_cost_sum} €/deň
                      </TableCell>
                      <TableCell colSpan={3} align="center">
                        {checkDate(item.imbalance_min_period_from)}
                      </TableCell>
                      <TableCell colSpan={3} align="center">
                        {checkDate(item.imbalance_max_period_from)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">{checkImbalanceValue(item.imbalance_min_nomination)}</TableCell>
                      <TableCell align="center">{checkImbalanceValue(item.imbalance_min_supply)}</TableCell>
                      <TableCell align="center">{checkImbalanceValue(item.imbalance_min_deviation)}</TableCell>
                      <TableCell align="center">{checkImbalanceValue(item.imbalance_max_nomination)}</TableCell>
                      <TableCell align="center">{checkImbalanceValue(item.imbalance_max_supply)}</TableCell>
                      <TableCell align="center">{checkImbalanceValue(item.imbalance_max_deviation)}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>

      <Stack marginTop={2} marginBottom={2}>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, height: '1px', backgroundColor: '#E0E0E0'}} />
        </div>
      </Stack>

      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        marginTop={2}
        marginBottom={4}
        spacing={1}
        sx={{gap: '12px', display: 'flex'}}
      >
        <Button onClick={() => handleDisplayGraphs()}>Zobraziť grafy</Button>
      </Stack>

      {previousSelectedDate === selectedDate && ( // for not rerendering (and then calling fetch) when changing dates and hiding the graphs
        <Grid container>
          {renderGraph.map((bpsId) => (
            <Grid key={bpsId} item xs={12} sm={6}>
              <Stack direction="column" sx={{width: '100%'}}>
                <Typography variant="h6" gutterBottom>
                  {bpsList.filter((bpsElement) => bpsElement.id == bpsId)[0].name}
                </Typography>
                <ProductionGraph key={bpsId} bpsId={bpsId} selectedDate={selectedDate} />
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}
      {isReportExportDialogOpen && (
        <ReportExportDialog open={isReportExportDialogOpen} onClose={handleCloseReportExportDialog} />
      )}
    </>
  )
}
