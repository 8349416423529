import {
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Create} from '@mui/icons-material'
import React, {useState} from 'react'
import {BpsPriceParams} from '../../../api/generated'
import {StyledIconButton} from '../GroupNominationsTable'

const StyledTableHeadRowTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const StyledTableCell = styled(TableCell)(() => ({
  width: '70%',
}))

type BpsPriceParamTableProps = {
  setIsEditBpsPriceParamsDialogOpen: (flag: boolean) => void
  bpsPriceParams: BpsPriceParams | undefined
  checkBoxBpsSupportsPayoff: boolean
}

export const BpsPriceParamsTable: React.FC<BpsPriceParamTableProps> = ({
  setIsEditBpsPriceParamsDialogOpen,
  bpsPriceParams,
  checkBoxBpsSupportsPayoff,
}) => {
  const [openTableRow, setOpenTableRow] = useState<number | undefined>(1)

  const handleOpenBpsPriceParams = (index: number) => {
    setOpenTableRow(index)
  }

  const handleCloseBpsPriceParamsTable = () => {
    setOpenTableRow(-1)
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{gap: '12px', display: 'flex'}}
        style={{marginTop: '30px'}}
      >
        <TableContainer component={Paper}>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableHead>
              <StyledTableHeadRowTable>
                <TableCell>Cenové parametre</TableCell>
                <TableCell />
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => (openTableRow == 1 ? handleCloseBpsPriceParamsTable() : handleOpenBpsPriceParams(1))}
                  >
                    {openTableRow == 1 ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </TableCell>
              </StyledTableHeadRowTable>
            </TableHead>

            <TableBody>
              <>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={openTableRow == 1} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table size="small" sx={{padding: '20px 0'}}>
                          <TableHead>
                            <StyledTableCell>Parametre</StyledTableCell>
                            <StyledTableCell>Hodnota</StyledTableCell>
                            <StyledTableCell style={{borderBottom: 'none'}}>
                              <StyledIconButton
                                sx={{marginLeft: '5px'}}
                                color="primary"
                                onClick={() => setIsEditBpsPriceParamsDialogOpen(true)}
                              >
                                <Create fontSize="small" />
                              </StyledIconButton>
                            </StyledTableCell>
                          </TableHead>
                          <TableBody>
                            <>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>Cena paliva</StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  {bpsPriceParams?.gas_price == undefined ? '-' : bpsPriceParams.gas_price} EUR/MWh
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none', verticalAlign: 'middle'}}>
                                  Podpora doplatkom
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none', display: 'flex'}}>
                                  <Checkbox style={{padding: 0}} checked={checkBoxBpsSupportsPayoff} disabled />
                                </StyledTableCell>
                              </TableRow>
                              {checkBoxBpsSupportsPayoff && (
                                <TableRow>
                                  <StyledTableCell style={{borderBottom: 'none'}}>
                                    URSO cenové rozhodnutie
                                  </StyledTableCell>
                                  <StyledTableCell style={{borderBottom: 'none'}}>
                                    {bpsPriceParams?.urso == undefined ? '-' : bpsPriceParams?.urso} EUR/MWh
                                  </StyledTableCell>
                                </TableRow>
                              )}
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none', verticalAlign: 'middle'}}>
                                  Fixný kontrakt
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none', display: 'flex'}}>
                                  {bpsPriceParams?.fix_contract_mw == undefined ? '-' : bpsPriceParams.fix_contract_mw}{' '}
                                  MW
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none', verticalAlign: 'middle'}}>
                                  Cena fixného konkraktu
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none', display: 'flex'}}>
                                  {bpsPriceParams?.fix_contract_price == undefined
                                    ? '-'
                                    : bpsPriceParams.fix_contract_price}{' '}
                                  EUR/MWh
                                </StyledTableCell>
                              </TableRow>
                            </>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  )
}
