/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetGroupNominationSnapshotsResponse } from '../model';
// @ts-ignore
import { GetNominationSnapshotsResponse } from '../model';
/**
 * NominationSnapshotControllerApi - axios parameter creator
 * @export
 */
export const NominationSnapshotControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominationSnapshots: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getGroupNominationSnapshots', 'date', date)
            const localVarPath = `/api/nomination-snapshot/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationSnapshots: async (bpsId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getNominationSnapshots', 'bpsId', bpsId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getNominationSnapshots', 'date', date)
            const localVarPath = `/api/nomination-snapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NominationSnapshotControllerApi - functional programming interface
 * @export
 */
export const NominationSnapshotControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NominationSnapshotControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupNominationSnapshots(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupNominationSnapshotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupNominationSnapshots(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominationSnapshots(bpsId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationSnapshotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominationSnapshots(bpsId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NominationSnapshotControllerApi - factory interface
 * @export
 */
export const NominationSnapshotControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NominationSnapshotControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominationSnapshots(date: string, options?: any): AxiosPromise<GetGroupNominationSnapshotsResponse> {
            return localVarFp.getGroupNominationSnapshots(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationSnapshots(bpsId: number, date: string, options?: any): AxiosPromise<GetNominationSnapshotsResponse> {
            return localVarFp.getNominationSnapshots(bpsId, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getGroupNominationSnapshots operation in NominationSnapshotControllerApi.
 * @export
 * @interface NominationSnapshotControllerApiGetGroupNominationSnapshotsRequest
 */
export interface NominationSnapshotControllerApiGetGroupNominationSnapshotsRequest {
    /**
     * 
     * @type {string}
     * @memberof NominationSnapshotControllerApiGetGroupNominationSnapshots
     */
    readonly date: string
}

/**
 * Request parameters for getNominationSnapshots operation in NominationSnapshotControllerApi.
 * @export
 * @interface NominationSnapshotControllerApiGetNominationSnapshotsRequest
 */
export interface NominationSnapshotControllerApiGetNominationSnapshotsRequest {
    /**
     * 
     * @type {number}
     * @memberof NominationSnapshotControllerApiGetNominationSnapshots
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof NominationSnapshotControllerApiGetNominationSnapshots
     */
    readonly date: string
}

/**
 * NominationSnapshotControllerApi - object-oriented interface
 * @export
 * @class NominationSnapshotControllerApi
 * @extends {BaseAPI}
 */
export class NominationSnapshotControllerApi extends BaseAPI {
    /**
     * 
     * @param {NominationSnapshotControllerApiGetGroupNominationSnapshotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationSnapshotControllerApi
     */
    public getGroupNominationSnapshots(requestParameters: NominationSnapshotControllerApiGetGroupNominationSnapshotsRequest, options?: AxiosRequestConfig) {
        return NominationSnapshotControllerApiFp(this.configuration).getGroupNominationSnapshots(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationSnapshotControllerApiGetNominationSnapshotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationSnapshotControllerApi
     */
    public getNominationSnapshots(requestParameters: NominationSnapshotControllerApiGetNominationSnapshotsRequest, options?: AxiosRequestConfig) {
        return NominationSnapshotControllerApiFp(this.configuration).getNominationSnapshots(requestParameters.bpsId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}
