import {
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {isBpsEditable, isViewOfPeriodDetailAllowed} from '../../../utils/validateUserRolesAndBps'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Create} from '@mui/icons-material'
import React, {useState} from 'react'
import {StyledIconButton} from '../../dashboard/GroupNominationsTable'
import {useBucketStore} from '../../../utils/BucketStoreContext'
import {transformToKwWithEndKwEvenIfNull, transformToKwWithEndKw} from '../../../utils/common'
import {BpsParametersExtended} from '../../../api/generated'

const StyledTableRowForBpsTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[200],
}))

const StyledTableHeadRowForBpsTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const TableHeadComponent = () => (
  <TableHead>
    <StyledTableHeadRowForBpsTable>
      <TableCell>Kód BPS</TableCell>
      <TableCell>Inštalovaný výkon [kW]</TableCell>
      <TableCell />
    </StyledTableHeadRowForBpsTable>
  </TableHead>
)

type BpsTableProps = {
  bpsParams: BpsParametersExtended
  onEditBps: () => void
  minVykonDos: string | undefined
  maxVykonDos: string | undefined
  minVykonReg: string | undefined
  maxVykonReg: string | undefined
}

export const BpsTable: React.FC<BpsTableProps> = ({
  bpsParams,
  onEditBps,
  minVykonDos,
  maxVykonDos,
  minVykonReg,
  maxVykonReg,
}) => {
  const [openTableRow, setOpenTableRow] = useState<number | undefined>(1)
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const handleOpenBpsParams = (index: number) => {
    setOpenTableRow(index)
  }

  const handleCloseBpsParams = () => {
    setOpenTableRow(-1)
  }

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{gap: '12px', display: 'flex'}}>
      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHeadComponent />

          <TableBody>
            <>
              <StyledTableRowForBpsTable key={bpsParams.id}>
                <TableCell>{bpsParams.code}</TableCell>
                <TableCell>{transformToKwWithEndKw(bpsParams.p_inst_mw)}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => (openTableRow == 1 ? handleCloseBpsParams() : handleOpenBpsParams(1))}
                    disabled={!isViewOfPeriodDetailAllowed(userInfo)}
                  >
                    {openTableRow == 1 ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </TableCell>
              </StyledTableRowForBpsTable>
              <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                  <Collapse in={openTableRow == 1} timeout="auto" unmountOnExit>
                    <TableContainer>
                      <Table size="small" sx={{padding: '20px 0'}}>
                        <TableHead>
                          <TableCell>Parameter</TableCell>
                          <TableCell>Hodnota</TableCell>
                          <TableCell />
                          <TableCell>
                            <StyledIconButton
                              sx={{marginLeft: '5px'}}
                              color="primary"
                              disabled={!isBpsEditable(userInfo, selectedBpsId)}
                              onClick={() => bpsParams.id && onEditBps()}
                            >
                              <Create fontSize="small" />
                            </StyledIconButton>
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          <>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Maximálna rezervovaná kapacita</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {' '}
                                {transformToKwWithEndKwEvenIfNull(bpsParams.max_reserved_capacity_mw)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Min. výkon dosiahnuteľný KGJ</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>{minVykonDos}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Max. výkon dosiahnuteľný KGJ</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>{maxVykonDos}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Min. výkon regulačný KGJ</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>{minVykonReg}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Max. výkon regulačný KGJ</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>{maxVykonReg}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Bezpečný (default) výkon generátorov</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {transformToKwWithEndKw(bpsParams.p_default_mw)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Tech. vlastná spotreba (TVS)</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {transformToKwWithEndKw(bpsParams?.tvs_mw == undefined ? 0 : bpsParams?.tvs_mw)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>TVS rozptyl</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {' '}
                                {transformToKwWithEndKw(bpsParams.tvs_variance_mw)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Ostatná vlastná spotreba</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {transformToKwWithEndKw(bpsParams.ovs_mw)}
                              </TableCell>
                            </TableRow>
                          </>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
