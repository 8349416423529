import {FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography} from '@mui/material'
import {NominationProfile, ProductionStatusResponse} from '../api/generated'
import {NominationExtendedBase} from '../types/nominationExtended'
import React from 'react'

type NominationDialogStatusSelectorProps = {
  productionStatusEnum: ProductionStatusResponse | undefined
  editedNomination: NominationExtendedBase | NominationProfile
  onValueChange: (value: number) => void
}

export const NominationDialogStatusSelector: React.FC<NominationDialogStatusSelectorProps> = ({
  productionStatusEnum,
  editedNomination,
  onValueChange,
}) => {
  return (
    <>
      <Stack>
        <Typography variant="caption">Prevádzková pripravenosť</Typography>
        <FormControl fullWidth sx={{marginBottom: '5px'}} size="small">
          <Select
            value={editedNomination.production_status?.toString()}
            sx={{
              color: 'white',
              backgroundColor: '#92C937',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            }}
            onChange={(event: SelectChangeEvent) => onValueChange(Number(event.target.value))}
          >
            {productionStatusEnum?.status?.map((e) => (
              <MenuItem key={e.value} value={e.value}>
                {e.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}
