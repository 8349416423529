import {
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, {useState} from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {BpsParametersExtended} from '../../../api/generated'
import {useBucketStore} from '../../../utils/BucketStoreContext'
import {isBpsEditable, isViewOfPeriodDetailAllowed} from '../../../utils/validateUserRolesAndBps'
import {Create} from '@mui/icons-material'
import {StyledIconButton} from '../GroupNominationsTable'

const StyledTableRowForBpsTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[200],
}))

const StyledTableHeadRowForBpsTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const TableHeadComponent = () => (
  <TableHead>
    <StyledTableHeadRowForBpsTable>
      <TableCell>Plynojem</TableCell>
      <TableCell>Objem [m3]</TableCell>
      <TableCell />
    </StyledTableHeadRowForBpsTable>
  </TableHead>
)

type GasTableProps = {
  bpsParams: BpsParametersExtended
  onEditGas: () => void
}

export const GasTable: React.FC<GasTableProps> = ({bpsParams, onEditGas}) => {
  const [openTableRow, setOpenTableRow] = useState<boolean>(true)

  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{gap: '12px', display: 'flex'}}>
      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHeadComponent />
          <TableBody>
            <>
              <StyledTableRowForBpsTable key={bpsParams.id}>
                <TableCell />
                <TableCell>{bpsParams.vol_gas_tank_m_3}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => (openTableRow == true ? setOpenTableRow(false) : setOpenTableRow(true))}
                    disabled={!isViewOfPeriodDetailAllowed(userInfo)}
                  >
                    {openTableRow == true ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </TableCell>
              </StyledTableRowForBpsTable>
              <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                  <Collapse in={openTableRow == true} timeout="auto" unmountOnExit>
                    <TableContainer>
                      <Table size="small" sx={{padding: '20px 0'}}>
                        <TableHead>
                          <TableCell>Parameter</TableCell>
                          <TableCell>Hodnota</TableCell>
                          <TableCell />
                          <TableCell>
                            <StyledIconButton
                              sx={{marginLeft: '5px'}}
                              color="primary"
                              disabled={!isBpsEditable(userInfo, selectedBpsId)}
                              onClick={() => bpsParams.id && onEditGas()}
                            >
                              <Create fontSize="small" />
                            </StyledIconButton>
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          <>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Min. prevádzkový objem</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {bpsParams.min_gas_tank_vol_m3 ?? '-'} m3
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{borderBottom: 'none'}}>Max. prevádzkový objem</TableCell>
                              <TableCell style={{borderBottom: 'none'}}>
                                {bpsParams.max_gas_tank_vol_m3 ?? '-'} m3
                              </TableCell>
                            </TableRow>
                          </>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
