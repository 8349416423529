import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'
import dayjs from 'dayjs'

type ConfirmCopyDayDialogProps = {
  open: boolean
  onClose: () => void
  dateFrom: string | null
  dateTo: string | null
  onConfirm: () => void
  confirmButtonDisabled: boolean
}

export const ConfirmCopyDayDialog: React.FC<ConfirmCopyDayDialogProps> = ({
  open,
  onClose,
  dateFrom,
  dateTo,
  onConfirm,
  confirmButtonDisabled,
}) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Skopírovať deň</DialogTitle>
        <DialogContent>
          <Typography>
            Želáte si skopírovať nominácie z dňa&nbsp;
            <strong>{dayjs(dateFrom).format('DD.MM.YYYY')}</strong> na deň&nbsp;
            <strong>{dayjs(dateTo).format('DD.MM.YYYY')}</strong>? Existujúce nominácie na deň&nbsp;
            <strong>{dayjs(dateTo).format('DD.MM.YYYY')}</strong> budú prepísané.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={onConfirm} variant="contained" disabled={confirmButtonDisabled}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
