import React from 'react'
import {Navigate, Route, Routes} from 'react-router'

import {RouteMap} from './RouteMap'
import {RootRouteContainer} from './route-containers/RootRouteContainer'
import {BrowserRouter} from 'react-router-dom'
import {Login} from '../components/login/Login'
import {Dashboard} from '../components/dashboard/Dashboard'
import {Dispatch} from '../components/dispatch/Dispatch'
import {DashboardRouteContainer} from './route-containers/DashboardRouteContainer'
import {DispatchRouteContainer} from './route-containers/DispatchRouteContainer'
import {LoginRouteContainer} from './route-containers/LoginRouteContainer'
import {useBucketStore} from '../utils/BucketStoreContext'
import {isUserGroupManagerOrGroupOperator} from '../utils/validateUserRolesAndBps'

export const NavigationRouter: React.FC = () => {
  const {data: userInfo} = useBucketStore('userInfo')
  const isUserLogged = userInfo != null
  const defaultRoute = isUserLogged ? RouteMap.Dashboard : RouteMap.Login
  const isGroupUser = isUserGroupManagerOrGroupOperator(userInfo)

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootRouteContainer />}>
            <Route index element={<Navigate replace to={defaultRoute} />} />

            {isUserLogged ? (
              <>
                {isGroupUser ? (
                  <>
                    <Route path={RouteMap.Dashboard} element={<DashboardRouteContainer />}>
                      <Route index element={<Dashboard />} />
                    </Route>

                    <Route path={RouteMap.Dispatch} element={<DispatchRouteContainer />}>
                      <Route index element={<Dispatch />} />
                    </Route>
                  </>
                ) : (
                  <>
                    <Route path={RouteMap.Dashboard} element={<DashboardRouteContainer />}>
                      <Route index element={<Dashboard />} />
                    </Route>
                  </>
                )}
              </>
            ) : (
              <>
                <Route path={RouteMap.Login} element={<LoginRouteContainer />}>
                  <Route index element={<Login />} />
                </Route>
              </>
            )}

            <Route path="*" element={<Navigate replace to={defaultRoute} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}
