import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import {common, datepicker} from './sk'

const resources = {
  sk: {
    common,
    datepicker,
  },
}

i18n.use(initReactI18next).init({
  resources: resources,
  fallbackLng: 'sk',
  simplifyPluralSuffix: true,
  debug: true,
})

export default i18n
