import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {Button, TextField} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers'
import dayjs, {Dayjs} from 'dayjs'
import React from 'react'

type ReportDatePickerProps = {
  value: string | undefined
  onChange: (date: string) => void
  showToolbar: boolean
}

export const ReportDatePicker: React.FC<ReportDatePickerProps> = ({value, onChange, showToolbar}) => {
  return (
    <>
      <Button
        variant="contained"
        sx={{padding: 0, minWidth: '48px'}}
        onClick={() => onChange(dayjs(value).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss'))}
      >
        <ArrowLeftIcon fontSize="large" />
      </Button>
      <MobileDatePicker<string, Dayjs>
        toolbarTitle=""
        value={value ?? ''}
        onChange={(newValue) => {
          if (newValue) {
            onChange(newValue.format('YYYY-MM-DDTHH:mm:ss'))
          }
        }}
        renderInput={(params) => <TextField size="small" {...params} />}
        componentsProps={{actionBar: {actions: ['cancel', 'accept']}}}
        showToolbar={showToolbar}
        disableHighlightToday
      />
      <Button
        variant="contained"
        sx={{padding: 0, minWidth: '48px'}}
        onClick={() => onChange(dayjs(value).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'))}
      >
        <ArrowRightIcon fontSize="large" />
      </Button>
    </>
  )
}
