import {FormControl, InputLabel, Select, MenuItem, Typography, Box} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {TIME_INTERVAL_MS} from '../utils/constants'
import {formatTimeRange} from '../utils/format'

const calculateOptions = (date: string, minPeriod?: number) => {
  const dayStart = dayjs(date).startOf('day')

  const dayEnd = dayjs(date).endOf('day')
  const diff = dayEnd.diff(dayStart) + 1

  const periodsCountInDay = diff / TIME_INTERVAL_MS

  const options = Array(periodsCountInDay)
    .fill(null)
    .map((_, index) => {
      const period = index + 1
      const timeFrom = dayStart.add(index * TIME_INTERVAL_MS)
      const timeTo = dayStart.add((index + 1) * TIME_INTERVAL_MS)
      return {period, timeFrom, timeTo}
    })

  if (minPeriod != null) {
    return options.filter((opt) => opt.period >= minPeriod)
  }
  return options
}

type PeriodPickerProps = {
  label: string
  date: string
  period: number
  onChange: (newPeriod: number) => void
  minPeriod?: number
  disabled?: boolean
}

export const PeriodPicker: React.FC<PeriodPickerProps> = ({label, date, period, onChange, minPeriod, disabled}) => {
  const options = calculateOptions(date, minPeriod)

  return (
    <>
      <FormControl fullWidth margin="normal" disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={period}
          label={label}
          onChange={(e) => onChange(Number(e.target.value))}
          MenuProps={{style: {maxHeight: '300px'}}}
        >
          {options.map((item) => (
            <MenuItem key={item.period} value={item.period}>
              <Box>
                <Typography fontWeight="bolder" marginRight={2} display="inline-flex">
                  #{item.period}
                </Typography>

                <Typography variant="subtitle2" display="inline-flex">{`${formatTimeRange(
                  item.timeFrom.toISOString(),
                  item.timeTo.toISOString(),
                )}`}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
