import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'

type ConfirmResetSlcDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  confirmResetDisabled: boolean
}

export const ConfirmResetSlcDialog: React.FC<ConfirmResetSlcDialogProps> = ({
  open,
  onClose,
  onConfirm,
  confirmResetDisabled,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Reset SLC regulačných rozsahov</DialogTitle>
      <DialogContent>
        <Typography>Naozaj si prajete vyžiadať reset SLC regulačných rozsahov?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={onConfirm} disabled={confirmResetDisabled} variant="contained">
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
