import {Container, styled} from '@mui/material'
import React from 'react'
import {Outlet} from 'react-router'

const StyledContainer = styled(Container)({
  marginTop: '50px',
  marginBottom: '100px',
})

export const DispatchRouteContainer: React.FC = () => {
  return (
    <StyledContainer maxWidth="lg">
      <Outlet />
    </StyledContainer>
  )
}
