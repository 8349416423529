import React from 'react'
import {Device} from '../api/generated'
import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material'

type NominationDialogDevicesTableProps = {
  devicesForBps: undefined | Device[]
  isSelected: (id: number | undefined) => boolean
  handleClickCheckBox: (id: number | undefined, checked: boolean) => void
}

export const NominationDialogDevicesTable: React.FC<NominationDialogDevicesTableProps> = ({
  devicesForBps,
  isSelected,
  handleClickCheckBox,
}) => {
  return (
    <>
      <Typography variant="caption">KGJ v prevádzke</Typography>

      <Table size="small" sx={{padding: '20px 0'}}>
        <TableBody>
          {devicesForBps
            ?.sort((a, b) => {
              const nameA = a.name ?? ''
              const nameB = b.name ?? ''
              return nameA.localeCompare(nameB)
            })
            .map((device, deviceIndex) => {
              return (
                <TableRow key={device.id}>
                  <TableCell key={devicesForBps?.at(deviceIndex)?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelected(devicesForBps?.at(deviceIndex)?.id)}
                          onChange={(_, checked) => {
                            handleClickCheckBox(devicesForBps?.at(deviceIndex)?.id, checked)
                          }}
                          disabled={!devicesForBps?.at(deviceIndex)?.in_service}
                        />
                      }
                      label={devicesForBps?.at(deviceIndex)?.name}
                      labelPlacement="end"
                      key={devicesForBps?.at(deviceIndex)?.id}
                    />
                    {!isSelected(devicesForBps?.at(deviceIndex)?.id) && (
                      <Typography variant="caption">Nevyrába</Typography>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </>
  )
}
