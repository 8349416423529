import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'

type ErrorUpdatingBpsDialogProps = {
  open: boolean
  onClose: () => void
  pInstMwValue: string
}

export const ErrorUpdatingBpsDialog: React.FC<ErrorUpdatingBpsDialogProps> = ({open, onClose, pInstMwValue}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Upraviť BPS</DialogTitle>
      <DialogContent>
        <Typography color={'#ff0000'}>Upozornenie:</Typography>
        <Typography color={'#ff0000'}>
          Bezpečný (default) výkon nesmie byť vyšší ako inštalovaný výkon, ktorý je {pInstMwValue}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zavrieť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
