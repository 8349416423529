import React from 'react'
import {NominationInterval} from '../components/AddNominationDialog'
import {PeriodPickerForHours} from '../components/PeriodPickerForHours'
import {hourPeriodToTimestamp, periodToTimestamp, timestampToHourPeriod, timestampToPeriod} from '../utils/common'
import {PeriodPicker} from '../components/PeriodPicker'
import {useBucketStore} from '../utils/BucketStoreContext'
import {NominationExtendedBase} from '../types/nominationExtended'
import {NominationProfile} from '../api/generated'
import {Typography} from '@mui/material'
import {formatTimeRange} from '../utils/format'
import dayjs from 'dayjs'

type NominationDialogIntervalSelectorProps = {
  editedNomination: NominationExtendedBase | NominationProfile
  selectedInterval: NominationInterval
  onEditProperties: (changes: Partial<NominationExtendedBase>) => void
  isProfileEdit: boolean
}

export const NominationDialogPeriods: React.FC<NominationDialogIntervalSelectorProps> = ({
  editedNomination,
  selectedInterval,
  onEditProperties,
  isProfileEdit,
}) => {
  const {data: dateFromBucketStore} = useBucketStore('selectedDate')
  const dateToUse = isProfileEdit ? dayjs().format('YYYY-MM-DD') : dateFromBucketStore
  const istALlDaySelected = selectedInterval === NominationInterval.AllDay

  return selectedInterval === NominationInterval.Hours || selectedInterval === NominationInterval.AllDay ? (
    <>
      <Typography variant="caption">
        Zvolený interval: {formatTimeRange(editedNomination.time_from, editedNomination.time_to)}
      </Typography>

      <PeriodPickerForHours
        label="Perióda od"
        date={dateToUse}
        period={timestampToHourPeriod(editedNomination.time_from, dateToUse)}
        onChange={(newValue) => {
          onEditProperties({time_from: hourPeriodToTimestamp(newValue, dateToUse).periodStart})
        }}
        disabled={istALlDaySelected}
      />
      <PeriodPickerForHours
        label="Perióda do"
        date={dateToUse}
        period={timestampToHourPeriod(editedNomination.time_to, dateToUse) - 1}
        onChange={(newValue) => {
          onEditProperties({time_to: hourPeriodToTimestamp(newValue, dateToUse).periodEnd})
        }}
        minPeriod={timestampToHourPeriod(editedNomination.time_from, dateToUse)}
        disabled={istALlDaySelected}
      />
    </>
  ) : (
    <>
      <PeriodPicker
        label="Perióda od"
        date={dateToUse}
        period={timestampToPeriod(editedNomination.time_from, dateToUse)}
        onChange={(newValue) => {
          onEditProperties({time_from: periodToTimestamp(newValue, dateToUse).periodStart})
        }}
        disabled={istALlDaySelected}
      />
      <PeriodPicker
        label="Perióda do"
        date={dateToUse}
        period={timestampToPeriod(editedNomination.time_to, dateToUse) - 1}
        onChange={(newValue) => {
          onEditProperties({time_to: periodToTimestamp(newValue, dateToUse).periodEnd})
        }}
        minPeriod={timestampToPeriod(editedNomination.time_from, dateToUse)}
        disabled={istALlDaySelected}
      />
    </>
  )
}
