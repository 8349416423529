import React from 'react'
import {Dialog, DialogContent, DialogTitle, Typography} from '@mui/material'

type UpdateNominationsAfterTvsUpdateDialogProps = {
  open: boolean
}

export const RecalculatingNominationsDialog: React.FC<UpdateNominationsAfterTvsUpdateDialogProps> = ({open}) => {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Úprava regulačného rozsahu</DialogTitle>
      <DialogContent>
        <Typography>Prebieha prepočítavanie regulačného rozsahu (môže trvať niekoľko desiatok sekúnd).</Typography>
      </DialogContent>
    </Dialog>
  )
}
