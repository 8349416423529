/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BpsRemoteControl
 */
export interface BpsRemoteControl {
    /**
     * 
     * @type {number}
     * @memberof BpsRemoteControl
     */
    'bps_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof BpsRemoteControl
     */
    'control_at_threshold': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BpsRemoteControl
     */
    'rc_enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BpsRemoteControl
     */
    'force_keep_bsp_rc_offer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BpsRemoteControl
     */
    'force_limited_responsivity': boolean;
    /**
     * 
     * @type {string}
     * @memberof BpsRemoteControl
     */
    'ctrl_pwr_rng_mode': BpsRemoteControlCtrlPwrRngModeEnum;
}

export const BpsRemoteControlCtrlPwrRngModeEnum = {
    RcrRngAvgCons: 'RCR_RNG_AVG_CONS',
    SlcRngRealCons: 'SLC_RNG_REAL_CONS'
} as const;

export type BpsRemoteControlCtrlPwrRngModeEnum = typeof BpsRemoteControlCtrlPwrRngModeEnum[keyof typeof BpsRemoteControlCtrlPwrRngModeEnum];


