import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {useBucketStore} from '../utils/BucketStoreContext'
import {formatTimeRange} from '../utils/format'
import {NominationExtendedBase} from '../types/nominationExtended'
import {transformToKwWithEndKwWithoutDecimals} from '../utils/common'
import {NominationExtended} from '../../src/api/generated'
import {checkOverlap, merge} from '../../src/components/nominations-merge-utils'

type ConfirmCreateNominationDialogProps = {
  open: boolean
  onClose: () => void
  nomination: NominationExtendedBase
  bpsNominationValue: number
  onConfirm: () => void
  isFailureTime: boolean
  confirmButtonDisabled: boolean
}

export const ConfirmCreateNominationDialog: React.FC<ConfirmCreateNominationDialogProps> = ({
  open,
  nomination,
  bpsNominationValue,
  onClose,
  onConfirm,
  isFailureTime,
  confirmButtonDisabled,
}) => {
  const {data: nominations} = useBucketStore('nominationsExtended')

  const existingRecords = useMemo(() => {
    const overlappingRecords = nominations.filter((x) => checkOverlap(x, nomination))
    return merge(overlappingRecords)
  }, [nominations, nomination])

  const NominationText = () => {
    return (
      formatTimeRange(nomination.time_from, nomination.time_to) +
      ', ' +
      nomination.value_percentage +
      '%, ' +
      transformToKwWithEndKwWithoutDecimals(bpsNominationValue)
    )
  }

  const ExistingNominationText = (existingNomination: NominationExtended) => {
    return (
      formatTimeRange(existingNomination.time_from, existingNomination.time_to) +
      ', ' +
      existingNomination.value_percentage +
      '%, ' +
      transformToKwWithEndKwWithoutDecimals(existingNomination.value_mw ?? 0)
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{isFailureTime ? 'Zadať poruchu' : 'Vytvoriť nomináciu'}</DialogTitle>
      <DialogContent>
        {isFailureTime ? (
          <Alert severity="error" sx={{marginBottom: '8px'}}>
            Zadávate poruchu
          </Alert>
        ) : null}
        {existingRecords.length > 0 && (
          <Box marginBottom={2}>
            <Typography fontWeight={500}>Existujúce nominácie:</Typography>
            {existingRecords.map((existingNomination) => (
              <Box key={existingNomination.id} marginTop="4px">
                <Typography>{ExistingNominationText(existingNomination)}</Typography>
                {existingNomination.note && (
                  <Typography variant="body2">Poznámka: {existingNomination.note}</Typography>
                )}
              </Box>
            ))}
          </Box>
        )}

        <Typography fontWeight={500}>{isFailureTime ? 'Porucha' : 'Nová nominácia'}:</Typography>
        <Typography>{NominationText()}</Typography>
        {nomination.note && <Typography variant="body2">Poznámka: {nomination.note}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color={isFailureTime ? 'error' : 'primary'}
          disabled={confirmButtonDisabled}
        >
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
