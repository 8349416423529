import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, {useEffect, useState} from 'react'
import {getNominationsV2} from '../../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {formatTimeRange, calculatePeriodFromIsoDate} from '../../utils/format'
import {ArrowBack} from '@mui/icons-material'
import {transformToKwNumber, transformToKwWithEndKw} from '../../utils/common'
import {Device, DeviceTypeEnum} from '../../api/generated'
import {getDevicesForBps} from '../../api/bpsApi'

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

export const NominationsTableDetail: React.FC = () => {
  const {setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {data: nominations, setData: setNominations} = useBucketStore('nominationsExtended')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: newSelectedDate} = useBucketStore('selectedDate')
  const {data: productionStatus} = useBucketStore('productionStatusEnum')

  const [open, setOpen] = useState<number | undefined>(-1)
  const [devicesForBps, setDevicesForBps] = useState<undefined | Device[]>(undefined)

  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runGetDevicesForBps} = useAsyncMethodWithErrorHandling(getDevicesForBps)

  const handleCloseNominationsDetail = () => {
    setBpsNominationTableOpen(false)
  }

  const getProductionStatusLabel = (value: number | undefined) => {
    return productionStatus?.status?.find((e) => e.value == value)?.label
  }

  const fetchNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = newSelectedDate.substring(0, 10)
      const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

      setNominations(result?.nominations ?? [])
    }
  }, [selectedBpsId, runGetNominationsV2, setNominations, newSelectedDate])

  const fetchDevicesForBps = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetDevicesForBps({id: selectedBpsId})).data
      setDevicesForBps(
        result?.devices.filter((device) => device.type === DeviceTypeEnum.Chp).sort((a, b) => (a.id > b.id ? 1 : -1)),
      )
    }
  }, [runGetDevicesForBps, selectedBpsId])

  useEffect(() => {
    fetchNominations()
    fetchDevicesForBps()
  }, [fetchNominations, fetchDevicesForBps])

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      marginTop={1}
      spacing={1}
      sx={{gap: '12px', display: 'flex'}}
    >
      <Button endIcon={<ArrowBack fontSize="large" />} onClick={() => handleCloseNominationsDetail()} color={'primary'}>
        Späť do kalendára
      </Button>

      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHead>
            <StyledTableHeadRow>
              <TableCell>#</TableCell>
              <TableCell>Čas</TableCell>
              <TableCell align="right">%</TableCell>
              <TableCell align="right">Nominácia [kW]</TableCell>
              <TableCell />
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {nominations.map((item) => (
              <>
                <StyledTableRow key={item.id}>
                  <TableCell>{calculatePeriodFromIsoDate(item.time_from)}</TableCell>
                  <TableCell>{formatTimeRange(item.time_from, item.time_to)}</TableCell>
                  <TableCell align="right">{item.value_percentage}</TableCell>
                  <TableCell align="right">{transformToKwNumber(item.value_mw)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => (open == item.id ? setOpen(-1) : setOpen(item.id))}
                    >
                      {open == item.id ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open == item.id} timeout="auto" unmountOnExit>
                      <Box sx={{margin: 1}}>
                        <b>Dosiahnuteľný rozsah KGJ:</b>{' '}
                        {transformToKwWithEndKw(
                          devicesForBps
                            ?.filter((device) =>
                              item.nomination_devices
                                ?.filter((nomination_device) => nomination_device.in_nomination)
                                .map((nomination_device) => Number(nomination_device.device_id))
                                .includes(device.id),
                            )
                            .reduce(function (prev, current) {
                              return prev + current?.p_dos_min_mw
                            }, 0),
                        )}{' '}
                        -{' '}
                        {transformToKwWithEndKw(
                          devicesForBps
                            ?.filter((device) =>
                              item.nomination_devices
                                ?.filter((nomination_device) => nomination_device.in_nomination)
                                .map((nomination_device) => Number(nomination_device.device_id))
                                .includes(device.id),
                            )
                            .reduce(function (prev, current) {
                              return prev + current?.p_dos_max_mw
                            }, 0),
                        )}
                        <br />
                        <b>Regulačný rozsah KGJ:</b>{' '}
                        {transformToKwWithEndKw(
                          item.nomination_devices?.reduce(function (prev, current) {
                            return prev + current?.p_reg_min_mw
                          }, 0),
                        )}{' '}
                        -{' '}
                        {transformToKwWithEndKw(
                          item.nomination_devices?.reduce(function (prev, current) {
                            return prev + current?.p_reg_max_mw
                          }, 0),
                        )}
                        <br />
                        <b>Prevádzková pripravenosť:</b> {getProductionStatusLabel(item.production_status)}
                        <br />
                        {item.note && item.note.length > 0 && <b>Poznámka:</b>}{' '}
                        {item.note && item.note.length > 0 && item.note}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button endIcon={<ArrowBack fontSize="large" />} onClick={() => handleCloseNominationsDetail()} color={'primary'}>
        Späť do kalendára
      </Button>
    </Stack>
  )
}
