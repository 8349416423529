import {Alert, Box, Button, Stack, TextField} from '@mui/material'
import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import {authenticateUser} from '../../api/authApi'
import {absolutePath} from '../../navigation/utils'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {accessTokenToUserInfo} from '../../utils/parseJwt'

export const Login: React.FC = () => {
  const {setData: setUserInfo} = useBucketStore('userInfo')
  const navigation = useNavigate()

  const [isAuthSuccessfull, setIsAuthSuccessfull] = useState<boolean>(true)

  const saveJwtTokenPayload = (accessToken: string | null) => {
    // TODO: validate parsed object structure
    setUserInfo(accessTokenToUserInfo(accessToken))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLoginResult = (loginResponse: any) => {
    const accessToken = loginResponse.accessToken
    sessionStorage.setItem('accessToken', accessToken)
    const isParsable = !!accessToken && typeof accessToken === 'string'
    if (!isParsable) {
      return
    }
    saveJwtTokenPayload(accessToken)
    setIsAuthSuccessfull(true)
    navigation(absolutePath('dashboard'))
  }

  const submit = async (email: string, password: string) => {
    try {
      const loginResult = await authenticateUser({login: email, password: password})
      // TODO: validate parsed object structure
      handleLoginResult(loginResult)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setIsAuthSuccessfull(false)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')?.toString()
    const password = data.get('password')?.toString()
    if (email && password) {
      submit(email, password)
    }
  }

  return (
    <Stack sx={{mt: 1}}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Používateľské meno"
          placeholder="Používateľské meno"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Heslo"
          placeholder="Heslo"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Alert severity="error" sx={{display: isAuthSuccessfull ? 'none' : 'flex'}}>
          Nepodarilo sa prihlásiť. Overte si zadávané údaje.
        </Alert>
        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
          Prihlásiť sa
        </Button>
      </Box>
    </Stack>
  )
}
